.project-status-layout{
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 16px;
    background-color: #f5f5f5;
    height: 85vh;
    border-radius: 10px;
}

.tabs-layout {
    /* margin-top: 20px; */
}

.tabs-header-run-history {
    display: flex;
    align-items: center;
    border-bottom: 2px solid #eaeaea;
    /* margin-bottom: 10px; */
}

.tab-item {
    padding: 8px 20px;
    cursor: pointer;
    font-weight: 500;
    color: #555;
    background-color: #f5f5f5;
    border-radius: 25px 25px 0 0;
    margin-right: 5px;
    transition: background-color 0.3s ease, color 0.3s ease;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    min-width: 150px;
    text-align: center;
}

.tab-item:hover {
    background-color: #e2e2e2;
    color: #333;
}

.tab-item.active {
    background-color: #fff;
    color: #000;
    font-weight: 600;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
    border-bottom: 2px solid white;
}

.tabs-content {
    padding: 20px;
    background-color: #fafafa;
    border-radius: 0 0 10px 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.tabs-content h2 {
    margin-top: 0;
    font-size: 22px;
    font-weight: 600;
    color: #333;
}

.tabs-content p {
    font-size: 16px;
    color: #666;
}

.project-column-header-project-status-tab{
    flex: 1;
    display: flex;
    justify-content: center; 
    align-items: center;   
    height: 100%; 
    padding: 10px 15px;
    font-size: 14px;
    color: #444;
    text-align: center; 
}

.project-status-tab-row{
    display: flex;
    border-bottom: 1px solid #e0e0e0;
    padding: 10px;
    transition: background-color 0.2s ease;
}

.project-status-tab-row:hover {
    background-color: #f9f9f9;
}

.project-status-tab-column {
    flex: 1;  
    padding: 10px 15px;
    font-size: 14px;
    color: #444;
    text-align: center; 
}

.project-status-details-layout{
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 16px;
    padding: 16px;
    /* background-color: #f18989; */
    background-color: var(--background-light);
    height: 68vh;
    border-radius: 10px;
}

.projects-status-listing {
    /* margin-top: 20px; */
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
    height: 53.5vh;
}
