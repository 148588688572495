.incremental-container-mapping {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
  
.incremental-dialog {
    width: 50rem; 
    height: 22rem; 
    background-color: #fff; 
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); 
    opacity: 1;
    display: flex;
    /* flex-direction: row; */
}

.first-column {
    flex: 1;
    max-width: 50%; 
    background-color: #f9f9f9;
    padding: 1rem;
    border-radius: 8px;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
}

.second-column {
    flex: 2;
    max-width: 50%; 
    background-color: #fff;
    padding: 1rem;
    border-radius: 8px;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
}

.table-name {
    cursor: pointer;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
    border: 1px solid #ddd; 
    border-radius: 4px;
    transition: background-color 0.3s;
}

.table-name:hover {
    background-color: #3F787E; 
    color: white;
}

.column-names {
    margin-left: 1rem; 
}

.column-name:hover {
    background-color: #3F787E; 
    color: white;
    cursor: pointer;
}

.column-names div {
    padding: 0.3rem;
    margin-bottom: 0.3rem;
    border: 1px solid #ddd; 
    border-radius: 4px;
}

.arrow {
    float: right;
}

.down {
    transform: rotate(90deg);
}

.destination-columns, .destination-column-headings {
    display: flex;
  }
  
  .destination-column{
    flex: 1;
    padding: 8px;
  }
  
  .destination-column:not(:last-child){
    border-right: 1px solid #ddd;
  }

  .selected-columns {
    margin-top: 8px;
  }
  
  .selected-columns div {
    padding: 4px;
  }
  
  .destination-columns-separator {
    width: 1px;
    background-color: #ddd;
    margin: 8px 16px;
  }


  /* Table name toggle switch */

  .toggle-switch-incremental {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    border-color: white;
  }

  .toggle-switch-incremental input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider-incremental {
    position: absolute;
    cursor: pointer;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40px;
    height: 17px;
    background-color: red;
    transition: 0.4s;
    border-radius: 34px;
  }
  
  .slider-incremental:before {
    position: absolute;
    content: '';
    width: 12px;
    height: 14px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
  }
  
  input:checked + .slider-incremental {
    background-color: #9CD9BC;
  }
  
  input:checked + .slider-incremental:before {
    transform: translateX(26px);
  }

  /* up/down toggle styles */

.table-name {
    transition: transform 0.5s ease;
  }
  
  .disabled {
    transform: translateY(5rem); 
  }
  