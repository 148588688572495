/* Message List Container */
.message-list {
    max-width: 900px;
    margin: 0 auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  
  /* Message Wrapper - Contains icon and message */
  .message-wrapper {
    display: flex;
    gap: 16px;
    align-items: flex-start;
    width: 100%;
  }
  
  .message-wrapper .icon {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
  }
  
  .message-wrapper.user .icon {
    background-color: #e9ecef;
    color: #495057;
  }
  
  .message-wrapper.bot .icon {
    background-color: #e3f2fd;
    color: #1976d2;
  }
  
  /* Message Styling */
  .message {
    flex: 1;
    padding: 16px;
    border-radius: 12px;
    position: relative;
    max-width: calc(100% - 52px);
  }
  
  .user-message {
    background-color: #f8f9fa;
    margin-right: 24px;
  }
  
  .bot-message {
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
    margin-left: 24px;
  }
  
  .message-content {
    color: #333;
    line-height: 1.5;
    margin: 0;
  }
  
  .message-timestamp {
    position: absolute;
    bottom: -20px;
    right: 8px;
    font-size: 12px;
    color: #6c757d;
  }

  /* Table Container Styles */
.table-container-message-list {
    margin: 16px 0;
    border-radius: 10px;
    overflow: hidden;
    border: 1px solid #e0e0e0;
    background-color: #ffffff;
  }
  
  /* Table Action Buttons */
  .table-container-message-list .table-actions {
    display: flex;
    gap: 8px;
    padding: 12px;
    background-color: #f8f9fa;
    border-bottom: 1px solid #e0e0e0;
    justify-content: flex-end;
  }
  
  .table-container-message-list .table-action-btn {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 16px;
    border: 1px solid #dee2e6;
    border-radius: 6px;
    background-color: #fff;
    color: #495057;
    cursor: pointer;
    transition: all 0.2s ease;
    font-size: 14px;
  }
  
  .table-container-message-list .table-action-btn:hover {
    background-color: #e9ecef;
    border-color: #adb5bd;
  }
  
  .table-container-message-list .table-action-btn.copied {
    background-color: #d4edda;
    color: #155724;
    border-color: #c3e6cb;
  }
  
  /* Table Scroll Container */
  .table-container-message-list .table-scroll {
    overflow-x: auto;
    max-width: 100%;
  }
  
  /* Table Base Styles */
  .table-container-message-list table {
    width: 100%;
    border-collapse: collapse;
    font-size: 14px;
    text-align: center;
  }
  
  /* Table Header Styles */
  .table-container-message-list thead {
    background-color: #f8f9fa;
  }
  
  .table-container-message-list th {
    padding: 12px 16px;
    font-weight: 600;
    color: #495057;
    border-bottom: 2px solid #dee2e6;
    text-align: center;
    position: relative;
  }
  
  /* Table Body Styles */
  .table-container-message-list tbody tr {
    border-bottom: 1px solid #dee2e6;
    transition: background-color 0.2s ease;
  }
  
  .table-container-message-list tbody tr:last-child {
    border-bottom: none;
  }
  
  .table-container-message-list tbody tr:hover {
    background-color: #f8f9fa;
  }
  
  .table-container-message-list td {
    padding: 12px 16px;
    color: #212529;
    text-align: center;
    vertical-align: middle;
  }
  
  /* Loading State */
  .table-container-message-list .loading {
    opacity: 0.7;
    pointer-events: none;
  }
  

  
  /* Markdown Content Styling */
  .message-content p {
    margin: 0 0 16px 0;
  }
  
  .message-content p:last-child {
    margin-bottom: 0;
  }
  
  .message-content pre {
    background-color: #f8f9fa;
    padding: 16px;
    border-radius: 6px;
    overflow-x: auto;
  }
  
  .message-content code {
    background-color: #f8f9fa;
    padding: 2px 6px;
    border-radius: 4px;
    font-size: 14px;
  }

  .error-message {
    color: #dc3545;
    background-color: #f8d7da;
    border: 1px solid #f5c6cb;
    padding: 8px 12px;
    border-radius: 4px;
    margin: 8px 0;
    font-size: 14px;
  }