.date-picker{
    border: 1px solid green;
    border-radius: 6px;  
    background-color: green;
    width: 280px;
    padding: 10px;
 }
 
 .head{
     color: #fff;
 }
 
 .dates{
     background: rgb(255, 255, 255);
     color: green;
     position: absolute;
     top: -5px;
     padding: 4px;
     font-size: 6px;
 }