  .sql-editor {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
    /* border: 1px solid #ccc; */
    border-radius: 8px;
    background-color: #f9f9f9;
    max-width: 100%;
    width: 100%;
    box-sizing: border-box;
    overflow: hidden;
  }
  
  .run-button {
    /* margin-top: 8px; */
    padding: 5px 10px;
    width: 6rem;
    background-color: #007acc;
    color: white;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    align-self: flex-start;
    transition: background-color 0.2s ease; 
  }
  
  .run-button:hover {
    background-color: #005ea3;
  }

  .run-button:disabled {
    background-color: #444;
    cursor: not-allowed;
  }
  
  .error-message {
    margin-top: 10px;
    color: #ff6b6b;
  }
  
  .query-result {
    border-radius: 8px;
    max-height: 240px; 
    overflow: auto;
    border-top: 1px solid #ddd;
    background-color: white;
    padding-top: 1rem;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
    position: relative;
  }
  
  .query-result table {
    border-collapse: collapse;
    width: 100%;
    table-layout: fixed; 
    min-width: 800px;
    table-layout: auto;
  }

  .query-result th {
    background-color: #f1f1f1;
    font-weight: bold;
    position: sticky;
    top: 0;
    z-index: 2;
    text-align: left;
    padding: 10px;
    border-bottom: 2px solid #ddd;
  }
  
  .query-result td {
    padding: 10px;
    text-align: left;
    border: 1px solid #ddd;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .query-result tbody tr:nth-child(odd) {
    background-color: #f9f9f9;
  }
  
  .query-result tbody tr:nth-child(even) {
    background-color: #e9e9e9;
  }
  
  .query-result tbody tr:hover {
    background-color: #d0ebff;
  }
  .query-result-wrapper {
    overflow-x: auto;
    width: 100%; 
    max-width: 100%; 
    box-sizing: border-box; 
}


  