.footer {
    display: flex;
    justify-content: start;
    text-align: center;
    align-items: center;
    color: #fff;
    /* border-top: 2px solid #7289da; */
    font-family: 'Poppins', sans-serif;
    width: 100%;
}

.footer-content {
    display: flex;
    gap: 3rem; 
    margin-top: 4px;
    flex-wrap: wrap;
    justify-content: start;
    align-items: center;
    width: 100%;
    max-width: 1200px;
}

.footer-content span {
    display: flex;
    align-items: center;
    justify-content: center; 
    gap: 10px; 
    font-size: 0.8rem; 
    font-weight: 500;
    text-align: center; 
    min-width: 120px; 
    transition: transform 0.2s ease, color 0.2s ease;
}

.status-icon {
    font-size: 1.5rem;
    width: 0.8rem;
    height: 0.8rem; 
}

.connected {
    color: #7ae582; 
}

.disconnected {
    color: #f44336; 
}

@media (max-width: 768px) {
    .footer-content {
        justify-content: center; 
        gap: 1.5rem; 
    }

    .footer-content span {
        margin-bottom: 8px;
        min-width: 100%; 
    }
}
