.sqlprojects-layout {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 16px;
    margin: 1rem 1rem 0 0;
    background-color: #D0C6B6;
    height: 80vh;
    border-radius: 25px;
  }

  .header-sql-exec-projects{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .add-sql-exec-projects{
    background-color: #000000;
    color: #FFCB22;
    border: 1px solid white;
    padding: 0 10px;
    border-radius: 8px;
    height: 70%;
    /* font-size: 13px; */
  }

  /*Project Details Layout */

.project-details-layout{
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 5px;
    /* padding: 16px; */
    /* background-color: #f18989; */
    /* background-color: var(--background-light); */
    height: 100%;
    border-radius: 10px;
}

  /* Search Container */

.searchbar-filter-sort-layout{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.search-container-sql-exec-projects {
    position: relative;
    display: flex;
    align-items: center;
    max-width: 850px;
    width: 100%;
}

.search-icon {
    position: absolute;
    left: 15px;
    font-size: 20px;
    color: #999;
    z-index: 1;
}

.search-bar-sql-exec-projects {
    width: 100%;
    padding: 10px 15px 10px 40px; 
    border: 1px solid #ddd;
    border-radius: 10px;
    font-size: 16px;
    transition: all 0.3s ease;
    outline: none;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.search-bar:focus {
    border-color: #007bff;
    box-shadow: 0 0 8px rgba(0, 123, 255, 0.3);
}

.search-bar::placeholder {
    color: #999;
    font-style: italic;
}

.filter-sort-selection{
    border-radius: 10px;
    padding: 0px 20px; 
    margin-left: 5px;
    border: 1px solid #ddd;
    background-color: #ffffff;
    color: #999;
    width: 100%;
    max-width: 25%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    height: 95%;
}

/* Dropdown Container */
.dropdown-container-sql-exec-projects {
    position: relative;
    display: inline-block;
    /* margin-left: 20px; */
}

/* Dropdown styling */
.dropdown-sql-exec-projects {
    padding: 10px 10px;
    font-size: 14px;
    border: none;
    border-radius: 10px;
    width: 100%;
    margin-left: 15px;
    max-width: 150px;
    appearance: none; /* This hides default dropdown arrow */
    background-color: white;
    padding-right: 40px; /* Space for the dropdown icon */
    cursor: pointer;
}

/* Dropdown Icon styling */
/* .dropdown-icon-sql-exec-projects {
    position: absolute;
    right: 0px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
    color: #999;
    font-size: 14px;
} */


.projects-listing {
    /* margin-top: 20px; */
    border: none;
    border-radius: 25px;
    background-color: #fff;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
    height: 56vh;
    width: 100%; 
}

.project-table-header {
    display: flex;
    padding: 10px;
    background-color: #f5f5f5;
    font-weight: 600;
    border-bottom: 1px solid #e0e0e0;
    color: #333;
    width: 100%;
}

.project-column {
    flex: 1;
    padding: 10px 15px;
    text-align: left;
    font-size: 14px;
    color: #555;
}

.project-row {
    display: flex;
    border-bottom: 1px solid #e0e0e0;
    padding: 10px;
    transition: background-color 0.2s ease;
}

.project-row:hover {
    background-color: #f9f9f9;
}

.project-column-header{
        flex: 1;
        display: flex;
        justify-content: center; 
        align-items: center;   
        height: 100%; 
        padding: 10px 15px;
        font-size: 14px;
        color: #444;
}

.project-column {
    flex: 1; 
    display: flex; 
    justify-content: center; 
    align-items: center;
    text-align: center; 
    padding: 0 10px;
    overflow: hidden;
    text-overflow: ellipsis; 
    white-space: nowrap;
}

.no-projects {
    text-align: center;
    padding: 20px;
    font-size: 16px;
    color: #888;
}