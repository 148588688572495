/* .dashboard-iframe {
  width: 94vw;
  height: 85vh;
  border: none;
  border-radius: 8px;
} */

.st-emotion-cache-6qob1r {
  background-color: blue;
}

.help-dialog img {
  max-width: 250px; /* Adjust the width as needed */
  max-height: 150px; /* Adjust the height as needed */
  width: auto; /* Maintain aspect ratio */
  height: auto; /* Maintain aspect ratio */
  border-radius: 4px;
  /* margin-top: 15px; */
}

.help-dialog {
  border: 3px solid #b4d99d;
}

.dashboard-layout {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  padding: 16px;
  background-color: #FFFFFF;
  /* height: 100vh; */
}

.flex-item {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 16px;
  transition: transform 0.2s, box-shadow 0.2s;
  /* height: 10%; */
}

.flex-item:hover {
  transform: translateY(-4px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.flex-item-1 {
  flex: 1 1 40%;
}

.flex-item-2 {
  flex: 1 1 40%;
}

.flex-item-3 {
  flex: 1 1 25%;
}

.flex-item-4 {
  flex: 1 1 60%;
}

.flex-item-5 {
  flex: 1 1 25%;
}

/* Control Section */
.control-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 16px;
  background-color: #D0C6B6;
  border-radius: 8px;
  margin-bottom: 16px;
}

.control-section .filters {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.control-section .filter-item {
  margin-left: 16px;
}

.custom-date-range {
  display: flex;
  align-items: center;
  margin-left: 16px;
}

.custom-date-range input {
  margin-left: 8px;
}


/* Scorecard Data */

.scorecard-data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-width: 600px;
  margin: 0 auto;
  background-color: #f8f9fa;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 1rem;
}

.scorecard-row-1, .scorecard-row-2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0.5rem 0;
}

.scorecard-item {
  padding: 1rem;
  margin: 0.5rem;
  width: calc(33% - 1rem);
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.scorecard-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
}

.scorecard-item label {
  font-weight: bold;
  color: #343a40;
  margin-right: 0.5rem;
  flex: 1;
}

.scorecard-item span {
  font-size: 1.2rem;
  color: #495057;
  flex: 1;
  text-align: right;
}


/* Skeletons when loading data */
@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}

.skeleton {
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
  border-radius: 4px;
}

.skeleton-text {
  height: 20px;
  margin: 10px 0;
}

.skeleton-box {
  height: 200px;
}


/* Table Data */
.dashboard-table-container {
  display: flex;
  flex-direction: column;
  padding: 5px;
  align-items: center;
}

.styled-table-dashboard {
  border-collapse: collapse;
  width: 100%;
  min-width: 400px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
  overflow: hidden;
}

.styled-table-dashboard thead tr {
  /* background-color: #009879; */
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
  color: #ffffff;
  text-align: left;
  position: sticky;
  top: 0;
  z-index: 1;
}

.styled-table-dashboard th,
.styled-table-dashboard td {
  padding: 10px 12px;
}

.styled-table-dashboard tbody {
  display: block;
  max-height: 300px; /* Adjust as needed */
  overflow-y: auto;
  overflow-x: hidden;
}

.styled-table-dashboard tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.styled-table-dashboard tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.styled-table-dashboard thead,
.styled-table-dashboard tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.scrollable-box-dashboard {
  overflow-y: auto;
  max-height: 300px; /* Adjust as needed */
  width: 100%;
}
