/* styles.css */

.time-input-group {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .input-field-clock {
    width: 50%;
  }
  
  .input-field-clock input {
    width: 100%;
    padding: 0.5rem;
    border-radius: 0.375rem;
    margin-right: 0.5rem;
    background-color: #3F787E;
    color: white;
    font-size: 1.125rem; /* 18px */
    text-align: center;
    /* placeholder: white; */
    border: none;
  }
  
  .input-field-clock input::placeholder {
    color: white;
  }
  
  .period-container {
    display: flex;
    flex-direction: column;
    margin-left: 0.5rem;
  }
  
  .period-button {
    border: 2px solid #6D743B;
    padding: 0.5rem;
    border-radius: 0.375rem;
    cursor: pointer;
  }
  
  .active-timer {
    background-color: #6D743B;
    color: white;
  }
  
  .rounded-border {
    border: 4px solid #3F787E;
    border-radius: 1.5rem;
    padding: 1rem;
  }

  .disabled-clock {
    opacity: 0.5; 
    pointer-events: none; 
  }
  