.breadcrumb {
    content: ">";  
    margin-top: 0.3rem;
    color: #64748B;
    font-size: 12px;  
  }

.breadcrumb-unit{
    margin: 0;
}
  
  /* Link Styles */
  .inactive-link {
    color: var(--green-dark);
    text-decoration: none;
    margin-top: 0cm;
    font-size: 12px;
  }
  
  .dark-mode .inactive-link {
    color: var(--dark-light);
  }
  
  .purple-mode .inactive-link {
    color: var(--purple-dark);
  }
  
  .red-mode .inactive-link {
    color: var(--red-dark);
  }
  
  .blue-mode .inactive-link {
    color: var(--blue-dark);
  }
  
  .yellow-mode .inactive-link {
    color: var(--yellow-dark);
  }
  
  
  .active-link {
    color: #000000;
    text-decoration: underline;
    text-decoration-thickness: 0.1em;
    text-underline-offset: 0.3em;
    margin-top: 0cm;
    font-size: 12px;
  }
  
  .dark-mode .active-link {
    color: var(--text-light);
    
  }
  