.profile-container {
    /* max-width: 600px; */
    width: 65%;
    margin: 0 auto;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}

.profile-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    margin-top: 20px;
}

.profile-tile {
    display: flex;
    flex-direction: column;
    padding: 15px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
}

.profile-tile:hover {
    transform: translateY(-5px);
}

.profile-tile label {
    font-size: 14px;
    color: #555;
    margin-bottom: 5px;
    font-weight: 600;
}

.profile-tile span {
    font-size: 16px;
    color: #333;
    font-weight: 500;
}

.editable {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.editable input {
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 5px;
    font-size: 16px;
    color: #333;
    width: 100%;
    margin-right: 10px;
}

.editable button {
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 6px 12px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.editable button:hover {
    background-color: #0056b3;
}

.profile-tile.editable input:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}
