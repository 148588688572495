.hour-incremental {
    display: flex;
    align-items: center;
    margin-top: -0.5cm;
    .hour-increment-btn {
        border: transparent;
        background-color: green;
        width: 30px;
        height: 25px;
        color: #fff;
        margin: 0px 30px;
    }

    .hour-inc {
        border: 1px solid #000;
        width: 40px;
        text-align: center;
    }
}