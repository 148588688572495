.collapse-control-layout {
    display: flex;
    flex-direction: column;
  }
  
  /* .hamburg-menu-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px; 
  } */
  
  .hamburg-menu {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-top: 1.5rem;
    color: var(--background-dark-1);
  }
  
  .dark-mode .hamburg-menu {
    color: var(--text-light);
  }
  
  .hamburg-menu svg {
    width: 30px;
    height: 30px;
  }
  
  .back-button-menu {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;
    margin-top: 1.5rem;
    margin-right: 0.5rem;
  }
  
  .dark-mode .back-button-menu svg {
    color: var(--text-light);
  }
  
  .back-button-menu svg {
    width: 25px;
    height: 25px;
  }
  
  .action-button-container {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .addProject-menu-button {
    margin-top: 0.5rem;
  }
  
  .addP-button {
    background-color: black;
    color: white;
    padding: 10px 15px;
    justify-content: center;
    align-items: center;
    border: none;
    margin-top: 1rem;
    border-radius: 10px;
    font-size: 10px;
    transition: background-color 0.3s, transform 0.3s;
    cursor: pointer;
  }
  
  .addP-button:hover {
    background-color: #888;
    transform: scale(1.05);
  }
  
  /* Mode-specific styles */
  .dark-mode .addP-button {
    background-color: var(--text-light);
    color: var(--text-dark);
  }
  
  .purple-mode .addP-button {
    background-color: var(--purple-dark);
    color: var(--text-light);
  }
  
  .red-mode .addP-button {
    background-color: var(--red-dark);
    color: var(--text-light);
  }
  
  .blue-mode .addP-button {
    background-color: var(--blue-dark);
    color: var(--text-light);
  }
  
  .yellow-mode .addP-button {
    background-color: var(--yellow-dark);
    color: var(--text-light);
  }
  
  .menu-text-sidebar {
    font-size: 14px;
    color: #333;
    font-weight: bold;
    margin-top: 1rem;
  }
  
  .dark-mode .menu-text-sidebar {
    color: var(--text-light);
  }
  