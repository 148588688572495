.editor-layout{
    /* display: flex; */
    /* padding: 16px; */
    background-color: var(--background-light);
    height: 100%;
    width: 100%;
    max-height: 76vh;
    border-radius: 25px;
    /* flex-direction: column; */
}

.editor-working-area{
    display: flex;
    background-color: var(--background-light);
    height: 95%;
    max-width: 100%;
    border-radius: 25px;
}

.editor-column-1 {
    flex: 1;
    max-width: 20%; 
    min-width: 100px; 
    background-color: #f5f5f5; 
    border-top-left-radius: 25px;
    border-bottom-left-radius: 10px;
    display: flex;
    flex-direction: column;
  }

  .connections-area {
    flex: 4; 
    min-height: 100px; 
    background-color: #e0e0e0; 
    padding: 10px;
    overflow-y: auto; 
    border-top-left-radius: 25px;
  }

  .connections-header{
    border-bottom: 2px solid #ccc; 
    display: flex;
    justify-content: space-between;
  }

  .editor-layout-titles {
    display: flex;
    align-items: center;
    font-size: 14px; 
    font-weight: bold;
    /* font-style:italic; */
  }

  .editor-control-buttons{
    display: flex;
    flex-direction: row;
  }

  .add-connection-button {
    all: unset; 
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 24px;
    height: 24px;
  }
  
  .add-connection-button svg {
    font-size: 16px; 
    font-weight: bold;
    color: #333; 
    transition: color 0.3s ease;
  }

  .add-connection-area{
    display: flex;
    flex-direction: column;
    text-align: center;
    font-size: 12px; 
    /* font-weight: bold; */
    border: 1px solid #ccc;
    margin: 5px 0;
    padding: 20px;
    cursor: pointer;
    gap: 10px;
    /* height: 100%; */
  }

  .add-connection-button-2 svg {
    font-size: 20px; 
    font-weight: bold;
    color: #333; 
    transition: color 0.3s ease;
  }

  .server-header {
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    gap: 15px;
    padding: 10px;
    /* background-color: #f4f4f8; */
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
    font-size: 14px;
    transition: background-color 0.3s;
  }
  
  .server-header:hover {
    background-color: #e0e0e5;
  }
  
  .database-list {
    /* margin-top: 10px; */
    padding-left: 15px;
    display: flex;
    flex-direction: column;
    /* gap: 8px; */
  }
  
  .database-item {
    display: flex;
    align-items: center;
    /* background-color: #f8f9fa; */
    padding: 8px;
    gap: 5px;
    border-radius: 4px;
    font-size: 14px;
    transition: background-color 0.3s;
    cursor: pointer;
  }
  
  .database-item:hover {
    background-color: #e9ecef;
  }
  
  
  .database-content {
    flex: 6; 
    min-height: 100px; 
    background-color: #d3d3d3; 
    padding: 10px;
    overflow-y: auto;
  }

  .metadata-section {
      /* border-top: 1px solid #ddd;
      padding-top: 1rem; */
  }

  .schema-select {
    display: flex;
    align-items: center;
    gap: 10px; 
    margin: 10px 0; 
  }
  
  .schema-dropdown-label {
    font-weight: bold;
    font-size: 14px;
    color: #333;
  }
  
  #schema-dropdown {
    /* padding: 8px; */
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 14px;
    background-color: #f9f9f9;
    transition: border-color 0.2s ease-in-out;
    cursor: pointer;
    width: 100%;
  }
  
  #schema-dropdown:focus {
    border-color: #007bff;
    outline: none;
  }
  
.schema-section {
  /* margin-bottom: 2rem; */
}

.schema-item {
  /* margin-bottom: 10px; */
}

.section-header {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  gap: 15px;
  cursor: pointer;
  font-weight: bold;
  font-size: 14px;
  padding: 10px;
  /* background-color: #f1f1f1; */
  border-radius: 5px;
}

.section-header:hover {
  background-color: #e0e0e0;
}

.toggle-icon {
  font-weight: bold;
}

.section-content {
  /* margin-top: 8px; */
  padding-left: 16px;
}

.table-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* margin-left: 8px; */
}

.table-labels{
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.row-label{
  font-size: 0.7rem;
  color: grey;
  align-self: center;
}

.columns-container-editor{
  padding-left: 16px;
}

.column-label-editor{
  font-size: 0.7rem;
  font-weight: bold;
  /* padding-left: 16px; */
}

.column-item{
  /* margin-left: 10px; */
  display: flex;
  justify-content: space-between;
  gap: 10px; 
  width: 100%;
}





  
.editor-column-2 {
    flex: 4;
    background-color: #ffffff;
    border-top-right-radius: 25px;
    flex-direction: column;
}

.editor-interface {
    flex: 1; 
    padding: 10px;
    overflow-y: auto;
    /* padding: 20px;   */
    background-color: #fff;
    max-height: 90%;
    /* border: 1px solid #ddd;
    min-height: 300px; */
  }

  .empty-interface {
    text-align: center;
    color: #888;
    font-style: italic;
    margin-top: 50px;
  }


.new-query-button {
    margin-top: 20px;
    padding: 10px 15px;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }

  .tabs-header {
    display: flex;
    background-color: #f0f0f0;
    border-bottom: 2px solid #ccc;
    border-top-right-radius: 25px;
  }
  
  .tab-button {
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 2px 2px;
    cursor: pointer;
    background-color: #e0e0e0;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    transition: background-color 0.3s ease;
    font-size: 12px;
  }
  
  .tab-button.active {
    background-color: white;
    /* color: white; */
    /* font-weight: bold; */
    font-size: 12px;
  }
  
  .close-icon {
    cursor: pointer;
    /* color: red; */
  }
  
  
  .results-table-area {
    flex: 0; 
    padding: 10px;
    display: none;
  }

  .editor-footer-area{
    background-color: #0466c8;
    border-bottom-right-radius: 25px;
    border-bottom-left-radius: 25px;
    color: white;
    height: 5%;
    text-align: center;
  }