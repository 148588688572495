.minimalist-sidebar {
    display: flex;
    flex-direction: column;
    width: 10%;
    min-width: 100px;
    max-width: 100px;
    /* background-color: aqua; */
    justify-content: space-between;
    height: 85vh;
  }
  
  .main-user-sections {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .main-menu-section,
  .user-control-section,
  .user-profile-section  {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    margin: 1rem auto;
    border-radius: 50px;
    width: 50%;
    position: relative; 
    overflow: hidden; 
  }
  
  .menu-item-minimalist-sidebar {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem 0;
    cursor: pointer;
    z-index: 2; 
    text-decoration: none; /* Removes the underline */
    color: inherit;
  }

.menu-item-minimalist-sidebar.active{
    /* background-color: #FFCB22; */
    color: #FFCB22;
}
  
  .circle-highlight {
    position: absolute;
    width: 100%;
    height: 3rem; 
    background-color: #000000;
    border-radius: 50%;
    z-index: 1;
    transition: top 0.3s ease; 
  }
  