.mapping {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* height: 50%; */
    margin-left: 2.5rem;
  }

  .incremental-mapping {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .scheduler-field {
    text-align: center;
  }
  
  .select-wrapper {
    width: 75%;
  }

  .table-container-mapping {
    display: flex;
    justify-content: center;
    overflow: auto;
    max-height: 17rem;
    width: 78%; 

  }

  .table-box-container{
    height: 50%;
  }

  .select-wrapper {
    width: 100%;
    border: 1px solid #CBD5E0; 
    padding: 0.5rem;
    z-index: 100;
  }