/* HamburgerMenu.css */
.hamburger-menu {
  position: fixed;
  left: -300px;
  width: 200px;
  height: 100%;
  background-color: white;
  transition: left 0.3s;
  top: 12%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.hamburger-menu.open {
  left: 0%;
  z-index: 2000;
}

.hamburger-icon {
  position: fixed;
  top: 60px;
  left: 4.5%;
  width: 20px;
  height: 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  cursor: pointer;
}

.bar {
  width: 30px;
  height: 4px;
  background-color: black;
  transition: background-color 0.3s, transform 0.3s;
}

.hamburger-menu.open .bar {
  background-color: black;
  transform: rotate(-45deg) translate(-4px, 5px);
}

.hamburger-menu.open .bar:nth-child(2) {
  opacity: 0;
}

.hamburger-menu.open .bar:nth-child(3) {
  background-color: black;
  transform: rotate(45deg) translate(-4px, -5px);
}

.menu-items {
  text-decoration: none;
  color: black;
  font-size: 16px;
  font-weight: bold;
  margin: 0; /* Remove margin to fully utilize container space */
  padding: 10px 20px;
  border-radius: 4px;
  transition: background-color 0.3s ease, color 0.3s;
  text-align: left;
  height: 80%; /* Make the menu items fill the full height of the menu */
  display: flex;
  flex-direction: column;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  justify-content: flex-start;
}

.menu-items li {
  margin: 15px 0;
  padding: 10px;
  background-color: black;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  border-radius: 5px;
  transition: background-color 0.3s;
  margin-bottom: 10px; /* Add margin to create a gap between list items */
}



.menu-items li a {
  text-decoration: none;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 18px;
  transition: color 0.3s;
}

.menu-items li {
  margin: 15px 0;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
/* Add some responsive styling */
/* @media (max-width: 768px) {
  .hamburger-menu {
    width: 80%;
  }

  .hamburger-icon {
    top: 10px;
    left: 10px;
  }
} */

.PROJECTadd1 {
  /* Common button styles */
  display: inline-block;
  padding: 10px 12px;
  background-color: black;
  color: white;
  border: none;
  border-radius: 10px;
  margin-top: 30px;
  font-size: 10px;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

.bottom-iconsbuttons {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align items to the left */
  gap: 10px; /* Adjust the gap between icons as needed */
}

.nav-links {
  display: flex;
  flex-direction: column; /* Arrange links vertically */
  align-items: center; /* Align items to the left */
  margin-top: 20px; /* Add spacing from the logo */
  width: 100%; /* Set width to 100% to fill the vertical space */
}


.nav-links > button,
.nav-links > a,
.logout-btn1,
.logout-button {
  text-decoration: none;
  color: #333; /* Darken the text color */
  font-size: 18px;
  margin: 0px; /* Decrease the margin here */
  
  transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out;
  padding: 8px 15px;
  border: none;
  border-radius: 8px;
  margin-top: 0%; /* Adjust the top margin as needed */
  margin-bottom: 10px;
  cursor: pointer;
  background-color: transparent; /* Set the background to transparent */
}

.nav-links > button
,
.nav-links > a,
.logout-btn1,
.logout-button {
 background-color: transparent;
}

.nav-links > button:hover,
.nav-links > a:hover,
.logout-btn1:hover,
.logout-button:hover {
  background-color:#6495ED	; /* Change to the desired hover background color */
  width: 170px; /* Same as default width */
}