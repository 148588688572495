.custom-sidebar {
    width: 250px;
    background-color: #71C9CE;
    border-radius: 10px;
    height: 85vh;
    display: flex;
    flex-direction: column;
    transition: width 0.3s ease;
    padding:10px;
  }
  
  .custom-sidebar.collapsed {
    width: 80px;
  }
  
  .menu-list-sidebar {
    display: flex;
    flex-direction: column;
    flex-grow: 1; 
  }
  
  .menu-item-sidebar {
    display: flex;
    align-self: center;
    padding: 0.8rem 0;
    cursor: pointer;
    color: var(--menu-text);
    transition: background-color 0.3s ease;
  }
  
  .menu-item:hover {
    background-color: var(--menu-hover-bg);
  }
  
  .menu-item svg {
    margin-right: 1rem;
  }
  
  .collapsed .menu-item span,
  .collapsed .menu-item a {
    display: none;
  }
  
  .sidebar-menu-text {
    font-size: 12px;
    color: var(--menu-text);
    font-weight: bold;
  }
  
  .sidebar-menu-text-profile {
    font-size: 14px;
    color: #333;
    font-weight: bold;
    margin-top: 1rem;
  }
  
  .pro-user-email {
    font-size: 12px;
  }
  
  .profile-section {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: auto;
  }
  
  .dark-mode .pro-user-email {
    color: #A0ABBB;
  }
  
  .purple-mode .pro-user-email {
    color: var(--purple-dark);
  }
  
  .red-mode .pro-user-email {
    color: var(--red-dark);
  }
  
  .blue-mode .pro-user-email {
    color: var(--blue-dark);
  }
  
  .yellow-mode .pro-user-email {
    color: var(--yellow-dark);
  }
  