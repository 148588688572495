.layout-main-container {
    display: flex;
    flex-direction: column;
    background-color: #F4F1EC;
    border-radius: 10px;
    margin: 1rem; 
    height: calc(100vh - 2.5rem); 
    width: calc(100vw - 2.5rem); 
    box-sizing: border-box; 
    overflow: hidden; 
}

.layout-main-header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 1rem;
    max-height: 10%;
}

.layout-main-body{
    max-height: 90%;
    /* background-color: aquamarine; */
    display: flex;
    flex-direction: row;
}

.control-section-header{
    display: flex;
    justify-content: center;
    flex-direction: row;
    gap: 1rem;
}

.logo-styles {
    width: 4.5rem;
    height: 2.5rem;
    /* height: 40px; */
    display: block;
    /* margin: 0 auto; */
}

.search-bar {
    flex: 1;
    display: flex;
    justify-content: center;
}

.search-bar input {
    width: 100%;
    padding: 8px 12px;
    border: 1px solid var(--border-color);
    border-radius: 20px;
    outline: none;
    font-size: 14px;
    background-color: white;
    color: var(--text-color);
}

.search-bar input::placeholder {
    color: #23262C;
}
  
.user-controls {
    display: flex;
    /* align-items: center; */
    justify-content: flex-end; 
    gap: 15px;
    height: 100%;
}

.icon {
    font-size: 20px;
    color: #23262C;
    cursor: pointer;
    transition: transform 0.2s ease, color 0.3s ease;
}

.icon:hover {
    color: var(--hover-icon-color);
    transform: scale(1.2);
}
  
.notification-icon,
.settings-icon {
        position: relative; 
}
  
.notification-icon::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 8px;
    height: 8px;
    background-color: red;
    border-radius: 50%;
    border: 2px solid var(--primary-bg-color);
}
  
.settings-icon {
    font-size: 22px; 
    padding: 2px;
    border-radius: 50%; /* Adds a circular hover effect */
    transition: background-color 0.3s ease, transform 0.2s ease;
}
  
.settings-icon:hover {
    background-color: var(--hover-bg-color);
    color: var(--hover-icon-color);
    transform: rotate(20deg) scale(1.2); /* Subtle rotation on hover */
}

.page-layout {
    flex: 1; 
    /* min-width: 1130px;
    max-width: 1200px; */
    /* margin-left: 105px; */
    padding: 0 0 0 20px;
    box-sizing: border-box; 
}