
/* .dark-mode {
    color: grey;
} */
/* Container Styles */

.settings-container {
    /* max-width: 600px; */
    width: 100%;
    height: 81vh;
    margin: 40px auto;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 6px 10px rgba(0,0,0,0.5);
    background-color: var(--green-default);
    display: flex;
    flex-direction: row;
}

.dark-mode .settings-container {
    color: #ecf0f1;
    background-color: var(--dark-light); 
    box-shadow: 0 6px 10px rgba(255, 255, 255, 0.1);
}

.settings-container.purple-mode  {
    color: #ecf0f1;
    background-color: var(--purple-default); 
}

.settings-container.red-mode  {
    color: #ecf0f1;
    background-color: var(--red-default); 
}

.settings-container.blue-mode  {
    color: #ecf0f1;
    background-color: var(--blue-default); 
}

.settings-container.yellow-mode  {
    color: #ecf0f1;
    background-color: var(--yellow-default); 
}

.settings-selection {
    flex: 1; 
    padding-right: 20px; 
    background-color: var(--green-default);
    color: #ecf0f1;
}

.dark-mode .settings-selection {
    color: #ecf0f1;
    background-color: var(--dark-light);
}

.settings-selection.purple-mode  {
    color: #ecf0f1;
    background-color: var(--purple-default); 
}

.settings-selection.red-mode  {
    color: #ecf0f1;
    background-color: var(--red-default); 
}

.settings-selection.blue-mode  {
    color: #ecf0f1;
    background-color: var(--blue-default); 
}

.settings-selection.yellow-mode  {
    color: #ecf0f1;
    background-color: var(--yellow-default); 
}

.settings-area {
    flex: 2; 
    background-color: var(--green-light);
    display: flex;
    flex-wrap: wrap;
    border-radius: 10px;
    /* height: 50vh; */
}

.dark-mode .settings-area {
    color: #ecf0f1;
    background-color: var(--dark-dark);
}

.settings-area.purple-mode  {
    color: #ecf0f1;
    background-color: var(--purple-light); 
}

.settings-area.red-mode  {
    color: #ecf0f1;
    background-color: var(--red-light); 
}

.settings-area.blue-mode  {
    color: #ecf0f1;
    background-color: var(--blue-light); 
}

.settings-area.yellow-mode  {
    color: #ecf0f1;
    background-color: var(--yellow-light); 
}

.theme-sample-container{
    width: calc(33.333% - 16px);
    /* height: 50%; */
    background-color: white;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 16px;
    
}

.theme-description-container{
    /* width: calc(33.333% - 16px); */
    margin-bottom: 16px;
    /* height: 100%; */
    background-color: blanchedalmond;
}
.theme-image-container{
    width: 28.5%; 
    margin: 2%;
    /* margin-right: 10%; */
    margin-left: 11.5%;
    height: auto;
    object-fit: cover;  
    /* border: 2px solid #ccc;  */
    /* border-radius: 8px; */
    transition: box-shadow 0.3s ease, transform 0.3s ease;
    /* background-color: azure; */
    flex-wrap: wrap;
}

.theme-image{
    width: 120%;
    height: 100%;
    border-radius: 10px;
}

.theme-image:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    transform: scale(1.20);
}


h2 {
    font-size: 24px;
    margin-bottom: 20px;
}

/* form > div {
    margin-bottom: 20px;
} */

.settings-options {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
}

.settings-options button {
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s ease, transform 0.2s ease;
    text-align: left;
    width: 50%;
    background-color: black;
    color: #fff; /* Ensuring text is white for all buttons for contrast */
}

.theme-toggle {
    background-color: black;
}

.theme-toggle:hover {
    background-color: black;
}

.language-toggle {
    background-color: black;
}

.language-toggle:hover {
    background-color: black;
}

.reset-history {
    background-color: black;
}

.reset-history:hover {
    background-color: black;
}

.recycle-bin {
    background-color: black;
}

.recycle-bin:hover {
    background-color: black;
}



label {
    cursor: pointer;
    display: flex;
    align-items: center;
}

input[type="checkbox"] {
    margin-right: 10px;
}

/* Additional styles for the Theme button to make it look more like a toggle than a button */
button.theme-toggle {
    background-color: transparent;
    color: inherit;
    border: 2px solid currentColor;
    padding: 5px 15px;
    font-size: 14px;
    transition: all 0.3s ease;
}

button.theme-toggle:hover {
    background-color: rgba(255,255,255,0.2);
}

.dark-mode button.theme-toggle:hover {
    background-color: rgba(0,0,0,0.2);
}