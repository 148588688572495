/* Global Styles */
body {
  margin: 0;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Container Styles */
/* .flex .dark-mode{
  background-color: var(--background-dark);
} */

.active-project-container {
  text-align: center;
  padding: 20px;
}

/* Logo Styles */
.logo-image {
  width: 150px;
  height: 80px;
  display: block;
  margin: 0 auto;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

/* Navigation Bar Styles */
/* .white-background {
  background-color: white;
  width: 100%;
  height: 50px;
  position: fixed;
  top: 0;
  left: 0;
} */

/* Heading Styles */
.active-project-container h3 {
  font-weight: bold;
  margin-bottom: 0;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

/* Breadcrumb Styles */

.breadcrumb .breadcrumb-item {
  content: ">";

  margin-top: 0.3rem;
  color: #64748B;
  font-size: 12px;  
}

/* Link Styles */
.inactive-link {
  color: var(--green-dark);
  text-decoration: none;
  margin-top: 0cm;
  font-size: 12px;
}

.dark-mode .inactive-link {
  color: var(--dark-light);
}

.purple-mode .inactive-link {
  color: var(--purple-dark);
}

.red-mode .inactive-link {
  color: var(--red-dark);
}

.blue-mode .inactive-link {
  color: var(--blue-dark);
}

.yellow-mode .inactive-link {
  color: var(--yellow-dark);
}


.active-link {
  color: #000000;
  text-decoration: underline;
  text-decoration-thickness: 0.1em;
  text-underline-offset: 0.3em;
  margin-top: 0cm;
  font-size: 12px;
}

.dark-mode .active-link {
  color: var(--text-light);
  
}


/* Button Styles */
.add-project-button {
  background-color: #3F787E;
  color: #fff;
  border: 1px solid white;
  padding: 0 10px;
  border-radius: 8px;
  font-size: 13px;
  margin-left: 100px;
  margin-top: 0cm;
}

.dark-mode .add-project-button{
  background-color: var(--dark-dark);
}

.purple-mode .add-project-button{
  background-color: var(--purple-dark);
}

.red-mode .add-project-button{
  background-color: var(--red-dark);
}

.blue-mode .add-project-button{
  background-color: var(--blue-dark);
}

.yellow-mode .add-project-button{
  background-color: var(--yellow-dark);
}

/* Subtitle Styles */
.sub-title {
  font-size: 12px;
  color: #666361;
  /* margin-left: 100px; */
}

.dark-mode .sub-title {
  color: var(--dark-light);
}

.purple-mode .sub-title {
  color: var(--purple-dark);
}

.red-mode .sub-title {
  color: var(--red-dark);
}

.blue-mode .sub-title {
  color: var(--blue-dark);
}

.yellow-mode .sub-title {
  color: var(--yellow-dark);
}

/* Navigation Tabs Styles */
.nav-tabs .nav-link {
  border: none;
  color: green;
  background-color: transparent;
  /* margin-left: 100px; */
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border: none;
  color: #3F787E;
  background-color: transparent;
  border-bottom: 3px solid #3F787E;
  font-weight: 600;
  margin-right: -80px;
}

.dark-mode .nav-tabs .nav-link.active,
.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus{
  color: var(--text-light);
  border-bottom: 3px solid var(--text-light);
}

.purple-mode .nav-tabs .nav-link.active,
.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus{
  color: var(--purple-dark);
  border-bottom: 3px solid var(--purple-dark);
}

.red-mode .nav-tabs .nav-link.active,
.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus{
  color: var(--red-dark);
  border-bottom: 3px solid var(--red-dark);
}

.blue-mode .nav-tabs .nav-link.active,
.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus{
  color: var(--blue-dark);
  border-bottom: 3px solid var(--blue-dark);
}

.yellow-mode .nav-tabs .nav-link.active,
.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus{
  color: var(--yellow-dark);
  border-bottom: 3px solid var(--yellow-dark);
}

/* Form Check Styles */
.custom-radio .form-check-input:checked {
  border: 2px solid #3F787E;
  position: relative;
  background-color: #00a2ed;
}

.custom-radio .form-check-input:checked::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 8px;
  height: 8px;
  background-color: #3F787E;
  border-radius: 50%;
}

/* Mainname Styles */
.page-heading {
  font-weight: bold;
  margin-bottom: 0;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  margin-top: 0;
}

.dark-mode .page-heading{
  color: var(--text-light);
}

/* Sort Container Styles */
.sort-container {
  margin-right: 2.5rem;
  /* margin-left: 100px; */
}

.dark-mode .sort-container{
  color: var(--text-light);    
}


.dark-mode .form-check-label{
  color: var(--text-light);    
}

/* Search Container Styles */
.search-container {
  background-color: var(--green-default);
  padding: 6px;
  border-radius: 10px;
  align-items: center;
}

.search-container.dark-mode {
  color: #ecf0f1;
  background-color: var(--dark-light);
}

.search-container.purple-mode{
  color: #ecf0f1;
  background-color: var(--purple-default);
}

.search-container.red-mode{
  color: #ecf0f1;
  background-color: var(--red-default);
}

.search-container.blue-mode{
  color: #ecf0f1;
  background-color: var(--blue-default);
}

.search-container.yellow-mode{
  color: #ecf0f1;
  background-color: var(--yellow-default);
}

/* Input Container Styles */
.input-container {
  position: relative;
}

/* .dark-mode .input-container {
  color: #ecf0f1;
  background-color: var(--dark-light);
} */

/* .purple-mode .input-container {
  color: #ecf0f1;
  background-color: var(--purple-default);
} */

.input-container input {
  width: 500px;
  border: none;
  border-radius: 10px;
  height: 37px;
  padding-left: 30px;
  margin-top: 8px;
}

.dark-mode .input-container input {
  color: #ecf0f1;
  background-color: var(--text-light);
}


.input-container-projects{
  position: relative;  
}

.input-container-projects input {
  width: 500px;
  border: none;
  border-radius: 10px;
  height: 37px;
  padding-left: 30px;
  margin: 0.3rem;
}

/* Icon Styles */
.icon {
  position: absolute;
  top: 44%;
  left: 6px;
  transform: translateY(-50%);
}

/* Filter Button Styles */
.filter-button {
  border: none;
  /* margin-top: 5px; */
  border-radius: 10px;
  background-color: #fff;
  color: #000;
  padding: 5px 15px;
  cursor: pointer;
}

/* Unfilled Icon Styles */
.unfilled-icon {
  color: #000;
  margin-right: 5px;
}

/* Active Styles */
.active {
  margin-right: 10px;
}

.dark-mode .custom-table-header{
  color: var(--text-light); 
}

/* Custom Table Container Row Styles */
.custom-table-container-row {
  display: flex;
  align-items: center;
  justify-content: left;
  border: 1px solid #3498db; /* Updated border color */
  margin: 2px 0; /* Further reduced space between rows */
  padding: 10px; /* Increased padding for better spacing */
  position: relative;
  height: 50px; /* Adjusted height for better spacing */
  background-color: #ecf0f1; /* Updated background color */
  color: #2c3e50; /* Updated text color */
  font-weight: bold;
  transition: transform 0.2s, box-shadow 0.2s;
  cursor: pointer;
  border-radius: 10px;
}

/* Custom Table Styles */
.custom-table {
  padding: 0px;
}

/* Filter Options Styles */
.filter-options {
  display: flex;
  flex-direction: column;
  position: absolute;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 5px;
  z-index: 1;
}

/* Filter Option Styles */
.filter-option {
  margin: 5px;
  padding: 5px 7px;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

/* Active Filter Option Styles */
.filter-option.active {
  background-color: white;
  color: black;
  border-radius: 4px;
}

/* Custom Left Styles */
/* .custom-left {
  border-radius: 10px;
  margin-left: 100px;
} */

/* Custom Right Styles */
.custom-right {
  border-radius: 10px;
}

/* Custom Overlay Styles */
/* .custom-overlay {
  position: absolute;
  top: 0;
  background-color: #ccc;
  height: 100%;
  width: 20%; 
  margin-right: 0;
  left: 0;
  cursor: pointer;
} */

/* Show Styles */
.show {
  display: block;
}

/* Not Show Styles */
.not-show {
  display: none;
}

/* Edit Link Styles */
.edit-link {
  display: flex;
  align-items: center;
  cursor: pointer;
}

/* Edit Text Styles */
.edit-text {
  margin-right: 5px;
  font-weight: bold;
  color: black;
}

.dark-mode .edit-text{
  color: var(--text-light); 
}

.dark-mode .custom-table-cell{
  color: var(--text-light); 
}



/* Pencil Icon Styles */
/* Style for the pencil icon */
.pencil-icon {
  color: black;
  font-size: 16px; /* Adjust the font size as needed */
  margin-left: 2px;
}

.dark-mode .pencil-icon{
  color: var(--text-light); 
}

/* Table Header Styles */
thead th {
  padding: 8px;
  font-weight: bold;
  /* left: -35px; */
  color: #333;
  text-align: center;
  /* position: relative; */
}

/* Table Header Before Styles */
/* thead th::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 60px;
  transform: translateY(-50%);
} */

/* Edit Container Styles */
.edit-container {
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 100px;
}

/* Help Icon Styles */
.help-icon {
  position: fixed;
  bottom: 0.5rem;
  right: 0.5rem;
  font-size: 24px;
  color: #333;
  cursor: pointer;
}

/* Help Dialog Styles */
.help-dialog {
  position: fixed;
  bottom: 60px;
  right: 20px;
  padding: 10px;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  z-index: 1000;
  max-width: 300px;
}

.help-dialog img {
  width: 40px; 
  height: auto,
}


.help-dialog p {
  margin: 0;
  padding: 0;
  font-size: 14px;
  color: #333;
}

/* Help Dialog Button Styles */
.help-dialog button {
  margin-top: 10px;
  padding: 5px 10px;
  background-color: green;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.help-dialog button:hover {
  background-color: #888;
}

/* Toggle Switch Styles */
.toggle-switch {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  /* height: 46px; */
  /* margin-top: 30px; */
}

/* Toggle Switch Input Styles */
.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* Slider Styles */
.slider {
  position: absolute;
  cursor: pointer;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40px;
  height: 17px;
  background-color: red;
  transition: 0.4s;
  border-radius: 34px;
}

/* Slider Before Styles */
.slider:before {
  position: absolute;
  content: '';
  width: 12px;
  height: 14px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

/* Input Checked Styles */
input:checked + .slider {
  background-color: #3F787E;
}

.dark-mode input:checked + .slider{
  background-color: var(--dark-light);
}

.purple-mode input:checked + .slider{
  background-color: var(--purple-dark);
}

.red-mode input:checked + .slider{
  background-color: var(--red-dark);
}

.blue-mode input:checked + .slider{
  background-color: var(--blue-dark);
}

.yellow-mode input:checked + .slider{
  background-color: var(--yellow-dark);
}

/* Input Checked Before Styles */
input:checked + .slider:before {
  transform: translateX(26px);
}

/* Toggle Switch Label Styles */
.toggle-switch-label {
  margin-left: 10px;
}

/* Status Button Styles */
.status-button {
  display: inline-block;
  width: 24px;
  height: 24px;
  border: none;
  /* border-color: var(--red-dark); */
  border-radius: 50%;
  font-size: 16px;
  cursor: pointer;
  outline: none;
}

/* Scrollable Div Styles */
.scrollable-div {
  overflow-y: auto;
}

/* Scrollable Cell Horizontal Styles */
.scrollable-cell-horizontal {
  white-space: nowrap;
  overflow-x: auto;
  border: 1px solid #ccc;
  padding: 5px;
  max-width: 100%;
}

/* Mainpage Styles */
.mainpage {
  background-color: black;
}

/* Add more styles based on your specific design preferences */
.source-configuration,
.target-configuration {
  max-height: 100px; /* Adjust the max height as needed */
  overflow-y: auto;
}

.scrollable-container {
  width: 550px; 
  overflow: x; 
  scrollbar-width: thin;
  scrollbar-color: green #ccc; 
  scrollbar-width: thin;
  scrollbar-color: green #ccc; 
}

.scrollable-textarea {
  width: 100%; /* Use 100% width within the container */
  white-space: nowrap; /* Prevent text from wrapping */
  overflow: auto; /* Enable both vertical and horizontal scrolling */
  scrollbar-width: thin; /* "thin" or "auto" based on your preference */
  scrollbar-color: green #ccc; /* Track color and thumb color */
  scrollbar-width: thin;
  scrollbar-color: green #ccc; /* Track color and thumb color */
}

.scrollable-cell {
  white-space: nowrap; /* Prevent text from wrapping */
  overflow: auto; /* Enable both vertical and horizontal scrolling */
  scrollbar-width: thin; /* "thin" or "auto" based on your preference */
  scrollbar-color: green #ccc; /* Track color and thumb color */
  scrollbar-width: thin;
  scrollbar-color: green #ccc; /* Track color and thumb color */
}

.none{
  left: 30px;
}

.none1{
  left: -10px;
}

.none2{
  left: -1px;
}

.scrollable-container {
  height: 200px;
  overflow-y: auto;
}
