.collapse-control-layout{
    /* display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column; */
}

.hamburg-menu {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-top: 1.5rem;
    color: var(--background-dark-1);
  }

.dark-mode .hamburg-menu{
    color: var(--text-light);
}
  
  .hamburg-menu svg {
    width: 30px; 
    height: 30px; 
    fill: #000; 
  }

.back-button-menu{
    display: flex;
    align-items: center;
    justify-content: right;
    cursor: pointer;
    margin-top: 1.5rem;
    margin-right: 0.5rem;
}

.dark-mode .back-button-menu svg{
  color: var(--text-light);
}

.back-button-menu svg{
    width: 25px; 
    height: 25px; 
}

.addProject-menu-button{
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-top: 0.5rem;
}

.addP-button{
    display: flex;
    background-color: black;
    color: white;
    padding: 10px 15px;
    justify-content: center;
    align-items: center;
    border: none;
    margin-top: 1rem;
    text-decoration: none;
    cursor: pointer;
    border-radius: 10px;
    font-size: 10px;
    transition: background-color 0.3s, transform 0.3s;
}

.dark-mode .addP-button {
    background-color: var(--text-light);
    color: var(--text-dark);
  }

  .purple-mode .addP-button {
    background-color: var(--purple-dark);
    color: var(--text-light);
    border: 1px solid var(--text-light);
  }

  .red-mode .addP-button {
    background-color: var(--red-dark);
    color: var(--text-light);
    border: 1px solid var(--text-light);
  }

  .blue-mode .addP-button {
    background-color: var(--blue-dark);
    color: var(--text-light);
    border: 1px solid var(--text-light);
  }

  .yellow-mode .addP-button {
    background-color: var(--yellow-dark);
    color: var(--text-light);
    border: 1px solid var(--text-light);
  }

.addP-button:hover {
    background-color: #888; 
    transform: scale(1.05); 
  }

  .red-mode .addP-button:hover {
    background-color: var(--red-medium); 
    transform: scale(1.05); 
  }

  .purple-mode .addP-button:hover {
    background-color: var(--purple-medium); 
    transform: scale(1.05); 
  }

  .blue-mode .addP-button:hover {
    background-color: var(--blue-medium); 
    transform: scale(1.05); 
  }

  .yellow-mode .addP-button:hover {
    background-color: var(--yellow-medium); 
    transform: scale(1.05); 
  }


  
.menu-text-sidebar{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: #333;
    font-weight: bold;
    margin-top: 1rem;
    /* margin-left: 11px; */
}

.dark-mode .menu-text-sidebar{
    color: var( --text-light);
    /* background-color: none !important; */
}