
.admin-dashboard-column1,
.admin-dashboard-column2{
    height: 100%;
    width: 100%;
    border-radius: 10px;
    flex-direction: column;
    
}

.admin-dashboard-column1{
    background-color:#F4F1EC;
    display: flex;
    flex: 5;
    margin: 1rem 0;
    gap: 1.5rem;
    /* padding: 1%; */
}

.admin-dashboard-column2{
    /* background-color: #f5f5f5; */
    flex: 1;
    display: flex;
    /* padding: 1%; */
    margin: 1rem 0;
    gap: 1.5rem;
}

.greeting-plus-row{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #D0C6B6;
    padding: 1%;
    border-radius: 10px;
}

.greeting-container{
    flex: 5;
}

.admin-dashboard-action{
    flex: 1;
    margin: 0 1%;
    border-radius: 10px;
    border: none;
}

.greeting {
    font-family: 'Poppins', sans-serif;
    font-size: 2rem;
    font-weight: 500;
    margin: 0;
    color: #23262C; 
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .data-visualisations-section{
    height: 100%;
    max-height: 68vh;
    width: 100%;
    /* padding: 1%;
    margin: 1% 0; */
    background-color: #F1F0E8;
    border-radius: 25px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }


  /* Column 2 - User Statistics and Active Users */

  .user-statistics{
    flex: 2;
    background-color: #FFFFFF;
    border-radius: 25px;
    padding: 1%;
    /* margin: 1% 0; */
  }

  .active-users-list{
    flex: 3;
    background-color:#FFFFFF;
    border-radius: 25px;
    padding: 1%;
    margin: 1% 0;
  }

  /* Form and Button Styles */

  
  .add-user-form {
    background-color: #F1F0E8;
    border: none;
    padding: 20px;

}

.form-header {
    font-size: 1.5rem;
    margin-bottom: 10px;
    color: #333;
}

.form-container label {
    display: block;
    margin-bottom: 10px;
    font-weight: bold;
    color: #555;
}

.form-container input {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 10px;
}

.form-buttons {
    margin-top: 20px;
    display: flex;
    gap: 10px;
}

.submit-button {
    background-color: #4caf50;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.cancel-button {
    background-color: #f44336;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.submit-button:hover {
    background-color: #45a049;
}

.cancel-button:hover {
    background-color: #e53935;
}

.admin-dashboard-section-headers{
    background-color: #000000;
    color: #FFCB22;
    /* padding: 1%; */
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: 16px;
    
}

/* All Users */


.user-status-dot {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 10px;
}

.user-status-dot.active {
    background-color: green;
}

.user-status-dot.inactive {
    background-color: red;
}

.user-list {
    list-style: none;
    padding: 10%;
    height: 90%;
}

.user-item {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.user-name {
    /* font-weight: bold; */
    margin-left: 4px;
    font-size: 0.9rem;
    cursor: pointer;
}

.user-email {
    font-size: 0.9em;
    color: gray;
    margin-left: 8px;
}

.no-users-message {
    text-align: center;
    color: gray;
    font-size: 1rem;
    margin-top: 10px;
}

