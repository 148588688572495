/* Digital Analog Clock Container */

.digital-analog-clock-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border: 5px solid var(--green-dark);
    background-color: var(--green-dark);
    border-radius: 10px;
    width: 75%;
    /* padding-left: 2%;
    padding-right: 2%;
    padding-top: 2%;
    padding-bottom: 2%; */
    margin-right: 2%;
}

.digital-analog-clock-container.dark-mode{
    border: 5px solid var(--dark-medium);
    background-color: var(--dark-medium);
}

.digital-analog-clock-container.purple-mode{
    border: 5px solid var(--purple-dark);
    background-color: var(--purple-dark);
}

.red-mode .digital-analog-clock-container{
    border: 5px solid var(--red-dark);
    background-color: var(--red-dark);
}

.blue-mode .digital-analog-clock-container{
    border: 5px solid var(--blue-dark);
    background-color: var(--blue-dark);
}

.yellow-mode .digital-analog-clock-container{
    border: 5px solid var(--yellow-dark);
    background-color: var(--yellow-dark);
}

.MuiTimeClock-root .css-umzx0k-MuiClock-pin{
    background-color: red;
}

.MuiTimeClock-root .css-d0vs79-MuiClockPointer-root{
    background-color: red;
}

.MuiTimeClock-root .css-rdq5h4-MuiClockPointer-root {
    background-color: red;
}

.MuiTimeClock-root .css-eg3pzz-MuiClockPointer-thumb{
    background-color: red;
    border: 16px solid red;
}

.MuiTimeClock-root .css-12t0dn4-MuiClockPointer-thumb{
    border: 16px solid red;
}