

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --background-light: #D9D9D9;
  --text-light: #E1E1E1;
  --text-dark: #333;
  --accent-light: #EDF3E8;
  --accent-dark: #333;
  --button-light: #8FAE76;
  --button-dark: #98B387;

  --green-light: #ddf5cb;
  --green-default:#b4d99d;
  --green-dark: #3F787E;

  --dark-light: #585861;
  --dark-medium: #1c1e21;  
  --dark-dark:  #0D1117;

  --purple-light: #dfd5f5;
  --purple-default: #B295F2;
  --purple-dark: #5F4A8B;

  --red-light: #f7b5c0;
  --red-default: #bd4a5e;
  --red-dark: #99152b;

  --blue-light: #c2f5ff;
  --blue-default: #63c3d6;
  --blue-dark: #02679e;

  --yellow-light: #e8dba0;
  --yellow-default: #c9b653;
  --yellow-dark: #7a6504;
  /* Add more colors as needed */


  --regular-background-darkest: #495464;
  --regular-background-dark: #BBBFCA;
  --regular-background-medium: #E8E8E8;
  --regular-background-light: #F4F4F2;

  --teal-background-darkest: #71C9CE;
  --teal-background-dark: #A6E3E9;
  --teal-background-medium: #CBF1F5;
  --teal-background-light: #E3FDFD;
}


.transformations-container {
  background-color: white;
  height: 85vh;
  display: flex;
  flex-direction: column;
  border-radius: 1rem;
  transition: background-color 0.3s ease;
}

.dark-mode .transformations-container {
  background-color: var(--background-dark);
}

/* Search container styles */

.transformations-search-container {
  position: relative;
  display: flex;
  align-items: center;
  background-color: #D0ABD9;
}

.transformations-search-input {
  width: 100%;
  background-color: #EDF3E8;
  padding: 2px 2px 2px 2px;
  border: 1px solid #ccc;
  border-radius: 1rem 1rem 0 0;
  height: 6vh;
}

.dark-mode .transformations-search-input {
  background-color: var(--background-dark);
}

.transformations-search-icon {
  position: absolute;
  left: 8px;
  cursor: pointer;
}

.transformations-search-icon svg {
  width: 1.2rem;
  height: 1.2rem;
  fill: #666;
}


.transformations-tables {
  flex-grow: 1;
  display: flex;
  height: 20vh;
  width: 100%;
  background-color: #D0ABD9;
}

.dark-mode .transformations-tables {
  background-color: var(--background-dark);
}

.search-table-transformations {
  display: flex;
  align-items: center;
  /* height: 100%; */
}

.search-table-input {
  width: 100%;
  background-color: white; 
  padding: 2px 2px 2px 2px;
  border: 1px solid #ccc;
  border-radius: 4px;
  height: 100%;
  box-shadow: none;
}

.dark-mode .search-table-input {
  background-color: var(--background-dark);
}

/* Navigation Bar */
.nav-bar {
  flex-direction: column; 
  justify-content: flex-start; 
  align-items: flex-start; 
  height: 100%; 
  width: 10%; 
  background-color: #b4d99d;
  padding: 20px;
  position: fixed; 
  top: 0;
  left: 0;
}

.large-icon {
  font-size: 26px; /* Adjust the font size as needed */
}

.bottom-icons {
  margin-top:200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0px; /* Adjust the gap between icons as needed */
}

.bottom-icons1 {
  margin-top: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px; /* Adjust the gap between icons as needed */
}

.bottom-icons-line {
  width: 100%;
  height: 1px;
  background-color: black; /* Adjust the color of the line */
  margin-top: auto; /* Adjust the margin-top as needed */
}


.button-container {
  display: flex;
  flex-direction: column; /* Arrange buttons vertically */
  align-items: flex-start; /* Align buttons to the left */
  margin-top: auto; /* Move icons to the bottom */
}
.button-containeradd{
  display: flex;
  flex-direction: column; /* Arrange buttons vertically */
  align-items: flex-start; /* Align buttons to the left */
  margin-top: auto; /* Move buttons to the bottom */
}

.dropdown {
  position: relative;
}

.dropdown-content {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  padding: 10px;
  display: none;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.user-email {
  font-size: 16px;
  color: #333;
}

.logo {
  display: flex;
  align-items: center;
  justify-content: center; /* Add this to center horizontally */
  margin-bottom: 20px; /* Add some spacing at the bottom */
}

.logo > img {
  height: 60px;
  width: auto;
  /* Remove the margin-left rule */
}


.nav-links {
  display: flex;
  flex-direction: column; /* Arrange links vertically */
  align-items: flex-start; /* Align items to the left */
  margin-top: 20px; /* Add spacing from the logo */
  width: 100%; /* Set width to 100% to fill the vertical space */
}


.nav-links > button,
.nav-links > a,
.logout-btn,
.logout-button {
  text-decoration: none;
  color: #333; /* Darken the text color */
  font-size: 18px;
  margin: 0px; /* Decrease the margin here */
  transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out;
  padding: 8px 15px;
  border: none;
  border-radius: 8px;
  margin-top: 0%; /* Adjust the top margin as needed */
  margin-bottom: 10px;
  cursor: pointer;
  background-color: transparent; /* Set the background to transparent */
}

.nav-links > button
,
.nav-links > a,
.logout-btn,
.logout-button {
 background-color: transparent;
}

.nav-links > button:hover,
.nav-links > a:hover,
.logout-btn:hover,
.logout-button:hover {
  transform: scale(1.05);
}


.login-container{
  display: flex;
  flex-direction:column;
  /* justify-content: center; */
  align-items: center;
  height: 100vh;
}


.login-page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
}

.login-form-background {
  margin-top: 30px;
  width: 600px;
  height: 500px;
  padding: 50px;
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  background-color: #fff;

}

.dark-mode  .login-form-background {
  background-color: var(--dark-light);
}

.purple-mode .login-form-background {
  background-color: var(--purple-default);
 }
 
 .red-mode .login-form-background {
  background-color: var(--red-default);
 }
 
 .blue-mode .login-form-background {
  background-color: var(--blue-default);
 }
 
 .yellow-mode .login-form-background {
  background-color: var(--yellow-default);
 }

.login-form-background form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dark-mode  .login-form-background form {
  background-color: var(--dark-default);
  color: black;
}

.purple-mode .login-form-background form {
  background-color: var(--purple-default);
 }
 
 .red-mode .login-form-background form {
  background-color: var(--red-default);
 }
 
 .blue-mode .login-form-background form {
  background-color: var(--blue-default);
 }
 
 .yellow-mode .login-form-background form {
  background-color: var(--yellow-default);
 }



.login-form-background form input {
  width: 100%;
  padding: 12px;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
}

.login-form-background form button {
  width: 100%;
  padding: 12px;
  margin: 20px 0;
  background-color: rgb(53, 57, 53);
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

.purple-mode .login-form-background form button {
  background-color: var(--purple-dark);
 }

 .red-mode .login-form-background form button {
  background-color: var(--red-dark);
 }
 
 .blue-mode .login-form-background form button {
  background-color: var(--blue-dark);
 }
 
 .yellow-mode .login-form-background form button {
  background-color: var(--yellow-dark);
 }

.login-form-background form button:hover {
  background-color: #333;
  transform: scale(1.05);
}

.welcomepage {
  font-size: 36px;
  color: #333;
  text-align: center;
  /* margin-top: 30px; */
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}

.dark-mode  .welcomepage {
  color: var(--dark-light);
}

.purple-mode .welcomepage{
 color: var(--purple-dark);
}

.red-mode .welcomepage{
 color: var(--red-dark);
}

.blue-mode .welcomepage{
 color: var(--blue-dark);
}

.yellow-mode .welcomepage{
 color: var(--yellow-dark);
}
/* Password Input */
.password-container {
  position: relative;
  width: 100%;
}

.password-input {
  width: 100%;
  padding: 12px;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
  transition: border-color 0.3s, box-shadow 0.3s;
}

.password-toggle {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
}

.password-input:focus ~ .password-toggle svg {
  fill: #007bff;
}

.menu-text {
  font-size: 14px; /* Adjust the font size as needed */
  color: #333;   /* Set the text color as needed */
  font-weight: bold;
  margin-left: 11px;
  margin-bottom: 0;
}
.entry{
  position: relative;
  left: 0; /* Change from -100% to 0 */
  display: flex;
  align-items: center;
  justify-content: flex-start; /* Align content to the left */
  padding: 10px;
  border-radius: 5px;
  width: 100%;
}

.PROJECTadd {
  /* Common button styles */
  display: inline-block;
  padding: 10px 15px;
  background-color: black;
  color: white;
  border: none;
  border-radius: 2px;
  margin-top: 30px;
  font-size: 10px;
  text-decoration: none;
  cursor: pointer;
  border-radius: 8px;
  transition: background-color 0.3s, transform 0.3s;
}

.PROJECTadd:hover {
  background-color: #888; /* Darken the background color on hover */
  transform: scale(1.05); /* Add a slight scale effect on hover */
}


.main-workspace-layout{
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
  /* padding: 3px;   */
  /* margin: 0 1rem 0 0; */
  /* background-color: #CBF1F5; */
  /* background-color: #F1F1F1; */
  height: 80vh;
  border-radius: 25px;
}

