.add-projects-sql-card-layout,
.add-projects-sql-header-layout {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 16px;
    padding: 16px;
    /* background-color: #f18989; */
    background-color: var(--background-light);
    height: 75vh;
    border-radius: 10px;
}

.add-projects-sql-header-layout{
    height: 10vh;
}

/* Header layout */
.add-projects-sql-header-layout {
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-bottom: 30px; */
}

/* Progress Indicator */
.progress-indicator {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.step-sql-add {
    display: flex;
    align-items: center;
    padding: 10px;
}

.circle-icon {
    display: inline-block;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #f0f0f0;
    border: 2px solid #ddd;
    color: #888;
    font-size: 15px;
    text-align: center;
    line-height: 50px;
    transition: background-color 0.3s, color 0.3s, border-color 0.3s;
}

.circle-icon.active {
    background-color: #3F787E;
    color: white;
}

.step-text {
    margin-left: 10px; /* Space between circle and text */
    font-size: 15px;
    color: #888;
}

.step-sql-add.active .step-text {
    color: #3F787E;
}
/* Card layout for forms */
.add-projects-sql-card-layout {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    min-height: 300px;
}

h4 {
    margin-bottom: 20px;
    font-size: 18px;
    color: #333;
}

/* Input styling */
/* input[type="text"] {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
} */

/* Navigation buttons */
.navigation-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* margin-top: 20px; */
}

.navigation-buttons-page-1{
    display: flex;
    justify-content: end;
    align-items: center;
}

.navigation-buttons-page-3 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    /* margin-top: 20px; */
}

.navigation-buttons button {
    display: inline-flex;
    align-items: center;  
    justify-content: center;
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    background-color: #3F787E;
    height: 70%;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s;
}

.navigation-buttons-page-1 button {
    display: inline-flex;
    align-items: center;  
    justify-content: center;
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    background-color: #3F787E;
    height: 70%;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s;
}

.navigation-buttons-page-3 button {
    display: inline-flex;
    align-items: center;  
    justify-content: center;
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    background-color: #3F787E;
    height: 70%;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s;
}

.navigation-buttons button:hover {
    background-color: var(--green-default);
}


.navigation-buttons-page-1 button:hover {
    background-color: var(--green-default);
}

.navigation-buttons-page-3 button:hover {
    background-color: var(--green-default);
}



.navigation-buttons button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}


.navigation-buttons-page-1 button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.navigation-buttons-page-3 button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.form-page-card-inner-layout{
    display: flex;
    flex-direction: column;
    /* width: 800px; */
}


.form-page-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    background-color: var(--background-light);
    border-radius: 10px;
    /* box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); */
    padding: 25px;
    /* min-width: 800px; */
    height: 45vh;
    transition: box-shadow 0.3s ease, transform 0.3s ease;
}

.form-page-card:hover {
    /* box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15); */
    transform: translateY(-3px);
}

.label-sql-add {
    font-size: 16px;
    font-weight: 600;
    color: var(--text-dark);
    margin-bottom: 10px;
}

.input-sql-add {
    width: 100%;
    padding: 12px 15px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 14px;
    margin-bottom: 10px;
    background-color: white;
    transition: border-color 0.3s ease;
}

.input-sql-add:focus {
    border-color: var(--primary-color);
    outline: none;
}

.form-two-columns {
    display: flex;
    flex-direction: row;
    justify-content: space-between; 
    align-items: flex-start;
    width: 100%;
    max-width: 1000px;
}

.form-column {
    display: flex;
    flex-direction: column;
    width: 48%; 
}

.form-column .input-sql-add {
    width: 100%;
}


/* klsdjfkjfvskdfn */

.error-sql-exec-add-projects{
    display: flex;
    justify-content:end;
    color: red;
    margin-top: 5px;
    font-size: 14px;
    /* font-weight: bold; */
}


/* Header containing the search input */
.stored-procedures-header {
    width: 100%;
    margin-bottom: 20px;
}

.search-input {
    width: 100%;
    padding: 12px 15px;
    border-radius: 5px;
    border: 1px solid #ddd;
    font-size: 14px;
    background-color: #fff;
    transition: border-color 0.3s ease;
}

.search-input:focus {
    border-color: var(--primary-color);
    outline: none;
}

/* Container for the stored procedure tiles */
.stored-procedures-list {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    width: 100%;
}

/* Individual tile for stored procedures */
.stored-procedure-item {
    display: flex;
    align-items: center;
    background-color: #fff;
    border-radius: 10px;
    border: 1px solid #ddd;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    padding: 15px;
    width: calc(33.33% - 10px); 
    transition: box-shadow 0.3s ease, transform 0.3s ease;
}

.stored-procedure-item:hover {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    transform: translateY(-3px);
}

.stored-procedure-item input[type="checkbox"] {
    margin-right: 10px;
    accent-color: var(--green-dark); /* Modern checkbox styling */
}

/* Container for the selected procedures */
.selected-procedures {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    width: 100%;
}

/* Individual selected procedure tag */
.selected-tag {
    background-color: var(--green-dark);
    color: #fff;
    border-radius: 5px;
    padding: 8px 12px;
    font-size: 14px;
    font-weight: 600;
}