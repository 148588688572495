.user-details__container {
    padding: 20px;
    font-family: Arial, sans-serif;
    max-width: 800px;
    margin: auto;
}

.user-details__heading {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
}

.user-details__content {
    display: flex;
    gap: 20px;
    align-items: flex-start;
}

.user-details__profile-picture {
    text-align: center;
}

.user-details__profile-picture-container {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    overflow: hidden;
    border: 2px solid #ccc;
    margin: 0 auto 10px;
    background-color: #f0f0f0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.user-details__profile-picture-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.user-details__profile-picture-placeholder {
    line-height: 120px;
    color: #888;
    font-size: 14px;
}

.user-details__upload-label {
    cursor: pointer;
    color: #007bff;
    /* font-weight: bold; */
    font-size: 0.9rem;
    /* text-decoration: underline; */
    transition: color 0.3s;
    text-align: center;
    justify-content: center;
}

.user-details__upload-label:hover {
    color: #0056b3;
}

.user-details__upload-input {
    display: none;
}

.user-details__info {
    flex: 1;
}

.user-details__card {
    border: 1px solid #ccc;
    border-radius: 25px;
    padding: 15px;
    background-color: #fff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.user-details__card h3 {
    margin-bottom: 10px;
    color: #333;
}

.user-details__card p {
    margin: 5px 0;
    line-height: 1.5;
    color: #555;
}

.user-details__no-data {
    text-align: center;
    margin-top: 20px;
    font-size: 18px;
    color: #888;
}
