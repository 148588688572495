/* Container Styles */
.visualization-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  
  .chart-wrapper {
    width: 100%;
    margin-top: 1rem;
    background-color: white;
    border-radius: 0.75rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    border: 1px solid #F3F4F6;
    padding: 2rem;
    transition: all 300ms;
  }
  
  .chart-wrapper:hover {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .chart-title {
    font-size: 1.25rem;
    font-weight: 700;
    color: #1F2937;
    margin-bottom: 1.5rem;
    letter-spacing: -0.025em;
  }
  
  /* No Data State */
  .no-data {
    width: 100%;
    padding: 2rem;
    text-align: center;
    color: #6B7280;
    background-color: #F9FAFB;
    border-radius: 0.75rem;
    border: 2px dashed #E5E7EB;
  }
  
  .no-data-title {
    font-size: 1.125rem;
    font-weight: 500;
  }
  
  .no-data-subtitle {
    font-size: 0.875rem;
    margin-top: 0.5rem;
  }
  
  /* Tooltip Styles */
  .tooltip {
    background-color: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(4px);
    border: 1px solid #F3F4F6;
    border-radius: 0.5rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 1rem;
  }
  
  .tooltip-label {
    font-size: 0.875rem;
    font-weight: 600;
    color: #4B5563;
    margin-bottom: 0.5rem;
  }
  
  .tooltip-entry {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 0.875rem;
  }
  
  .tooltip-color-dot {
    width: 0.75rem;
    height: 0.75rem;
    border-radius: 9999px;
  }
  
  .tooltip-name {
    font-weight: 500;
    color: #374151;
  }
  
  .tooltip-value {
    color: #4B5563;
  }
  
  /* Legend Styles */
  .legend {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1.5rem;
    padding-top: 1rem;
  }
  
  .legend-entry {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  
  .legend-color-dot {
    width: 1rem;
    height: 1rem;
    border-radius: 9999px;
    transition: transform 200ms;
  }
  
  .legend-color-dot:hover {
    transform: scale(1.1);
  }
  
  .legend-label {
    font-size: 0.875rem;
    font-weight: 500;
    color: #4B5563;
  }
  
  /* Responsive Adjustments */
  @media (min-width: 640px) {
    .chart-wrapper {
      width: 75%;
    }
  }
  
  @media (min-width: 768px) {
    .chart-wrapper {
      width: 100%;
    }
  }
  
  @media (min-width: 1024px) {
    .chart-wrapper {
      width: 150%;
    }
  }
  
  /* Unsupported Type Message */
  .unsupported-type {
    text-align: center;
    color: #6B7280;
  }