/* style/Project2.css */

body {
    margin: 0;
    font-family: 'Arial', sans-serif;
  }
  
  .project-main-container {
    display: flex;
    flex-direction: column;
    height: 85vh;
    background: #f4f6f8; 
    padding: 20px;
    box-sizing: border-box;
  }
  
  nav[aria-label="breadcrumb-trails"] {
    height: 5%;

  }
  
  .breadcrumb-unit {
    list-style: none;
    padding: 0;
    margin: 0; /* Reset margin if needed */
    display: flex;
    align-items: center; /* Vertically center items */
    height: 100%; /* Ensure the container has a height */
    color: #64748B;
  }
  
  
  .breadcrumb-unit li {
    margin-right: 10px;
    position: relative;
  }
  
  .breadcrumb-unit li:not(:last-child)::after {
    content: '>';
    margin-left: 10px;
    color: #64748B;
  }
  
  .breadcrumb-unit a {
    color: rgb(0, 0, 0);
    text-decoration: none;
    font-size: 12px;
    transition: color 0.3s;
  }
  
  .breadcrumb-unit a:hover {
    /* color: #18bc9c;  */
  }
  
  .breadcrumb-unit .active-link {
    font-weight: bold;
    color: #000000;
    text-decoration: underline;
    text-decoration-thickness: 0.1em;
    text-underline-offset: 0.3em;
    margin-top: 0cm;
    font-size: 12px;
  }
  
  .breadcrumb-unit .dark-mode {
    color: #dfe6e9; /* Light grey for dark mode */
  }
  
  .control-header {
    background: white;
    padding: 1.5rem;
    margin: 1rem 0;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .control-header h1 {
    font-size: 1.8rem;
    color: #333; /* Dark text for readability */
  }
  
  .control-header .controls {
    display: flex;
    gap: 1rem;
  }
  
  .control-header .controls button {
    /* background: #18bc9c;  */
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    transition: background 0.3s;
  }
  
  .control-header .controls button:hover {
    /* background: #16a085;  */
  }
  