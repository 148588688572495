.source-tiles {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: center;
}

.source-tile {
    width: 300px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f0f0f0;
    border-radius: 8px;
    cursor: pointer;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease;
}

.source-tile:hover {
    background-color: #e0e0e0;
}

.config-details-missing {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    color: #d9534f; 
    /* background-color: #f8d7da; 
    border: 1px solid #f5c6cb;  */
    border-radius: 8px; 
    padding: 16px; 
    margin: 20px auto; 
    max-width: 500px; 
    /* box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);  */
    transition: all 0.3s ease; 
}

.config-details-missing:hover {
    /* background-color: #f5c6cb;  */
    transform: scale(1.02); 
}


.label-sql-add, .input-sql-add {
    margin: 5px 0;
}

.navigation-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    margin-top: 1rem;
}

.form-page-card-source-target-selection {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    background-color: var(--background-light);
    border-radius: 10px;
    /* box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); */
    padding: 20px 5px;
    min-width: 800px;
    max-height: 80%;
    height: 60vh;
    transition: box-shadow 0.3s ease, transform 0.3s ease;
}

.navigation-buttons-single {
    display: flex;
    justify-content: flex-end; 
    padding: 16px;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    background-color: var(--background-light);
    height: 12%;    
    cursor: pointer;
    transition: background-color 0.3s;
}


.navigation-buttons-add-project{
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    /* background-color: #3F787E; */
    background-color: var(--background-light);
    height: 12%;    
    cursor: pointer;
    transition: background-color 0.3s;
}

button.next-button-add-project{
    background-color: #3F787E;
    border-radius: 4px;
    padding: 5px 20px;
    color: white;
    border: none;
}

.final-step-buttons{
    display: flex;
    gap: 1rem;
}

button.save-button-add-project{
    background-color: #3F787E;
    border-radius: 4px;
    padding: 5px 20px;
    color: white;
    border: none;
}

button.save-and-run-button-add-project{
    background-color: #3F787E;
    border-radius: 4px;
    padding: 5px 20px;
    color: white;
    border: none;
}

.source-target-label{
    font-size: 14px;
    /* font-weight: bold; */
}

.add-projects-header-layout{
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 16px;
    padding: 16px;
    background-color: var(--background-light);
    /* height: 75vh; */
    border-radius: 10px;
    height: 12%;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Spinner styles and animation */

.spinner {
    animation: spin 1s linear infinite;
    margin-right: 8px; 
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  .save-button-add-project.loading {
    background-color: #ccc; 
    cursor: not-allowed;
    opacity: 0.8;
  }
  