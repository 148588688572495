
.logo-styles{
    width: 10vw;
    height: 10vh;
}
.scheduler-container {
    display: flex;
    flex-direction: column;
    /* border: 1px solid var(--green-dark); */
    padding: 20px;
    border-radius: 10px;
    width: 100%;
    height: auto; 
    position: relative;
    box-shadow: 0 6px 10px rgba(0,0,0,0.5);
    background-color: var(--green-default);
}

.dark-mode .scheduler-container{
    border: 1px solid var(--dark-light);
    background-color: var(--dark-light);
}

.purple-mode .scheduler-container{
    /* border: 1px solid var(--purple-dark); */
    background-color: var(--purple-default);
}

.red-mode .scheduler-container{
    /* border: 1px solid var(--red-dark); */
    background-color: var(--red-default);
}

.blue-mode .scheduler-container{
    /* border: 1px solid var(--blue-dark);   */
    background-color: var(--blue-default);
}

.yellow-mode .scheduler-container{
    /* border: 1px solid var(--yellow-dark); */
    background-color: var(--yellow-default);
}


.scheduler-field {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    width: 90%;
  }
  
  .scheduler-label {
    width: 40%;
    font-size: 1.125rem; 
    color: var(--green-dark); 
    margin-right: 0.5rem;
  }

.scheduler-label.dark-mode {
    color: var(--text-light);
}

.scheduler-label.purple-mode {
    color: var(--purple-dark);
}

.red-mode .scheduler-label {
    color: var(--text-light);
}

.scheduler-label.blue-mode {
    color: var(--blue-dark);
}

.scheduler-label.yellow-mode {
    color: var(--yellow-dark);
}
  
  .input-field {
    width: 100%;
    border: 1px solid #CBD5E0;
    padding: 0.5rem;
  }

  .select-wrapper-scheduler {
    width: 100%;
    border: 1px solid #CBD5E0; 
    padding: 0.5rem;
    /* overflow: auto; */
    /* max-height: 40px;
    max-width: 100%; */
  }

  .select-wrapper-scheduler-dm-projects{
    width: 70%;
  }

.scheduler-checkbox {
    margin-left: 10px;
}

.select-container{
    width: 100%;
}

.enabled-label {
    margin-left: 5px; 
    font-size: 14px; 
    color: #333; 
}

.styled-container {
    position: relative;
    display: flex;
    align-items: center;
    padding-top: 1.2rem;
    padding-bottom: 1.2rem; 
    margin-right: 1rem;
}

.form-section {
    margin-bottom: 1rem;
}

.scheduler-type-container {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.scheduler-type-label {
    flex-shrink: 0;
    margin-right: 10px;
}

.scheduler-type-input {
    flex-grow: 1;
    width: 100%;
}

.scheduler-checkbox {
    margin-left: 10px;
}

/* .frequency-container {
    display: flex;
    flex-wrap: wrap;
    width: 90%;
  } */
/* .column {
    width: 100%;
  } */
  
  /* @media (min-width: 640px) {
    .column {
      width: 50%;
    }
  } */

.styled-divider-left {
    flex-grow: 0;
    border-top: 1px solid var(--green-dark);
    width: 5rem;
}

.dark-mode .styled-divider-left {
    border-top: 1px solid var(--text-light);
}

.purple-mode .styled-divider-left {
    border-top: 1px solid var(--purple-dark);
}

.red-mode .styled-divider-left {
    border-top: 1px solid var(--text-light);
}

.blue-mode .styled-divider-left {
    border-top: 1px solid var(--blue-dark);
}

.yellow-mode .styled-divider-left {
    border-top: 1px solid var(--yellow-dark);
}

.styled-text {
    font-size: 1.3rem;
    margin-left: 1rem;
    margin-right: 1rem;
    color: var(--green-dark);
    flex-grow: 0;
}

.dark-mode .styled-text {
    color: var(--text-light);
}

.purple-mode .styled-text {
    color: var(--purple-dark);
}

.red-mode .styled-text {
    color: var(--text-light);
}

.blue-mode .styled-text {
    color: var(--blue-dark);
}

.yellow-mode .styled-text {
    color: var(--yellow-dark);
}


.styled-divider-right {
    flex-grow: 1;
    border-top: 1px solid var(--green-dark);
}

.dark-mode .styled-divider-right {
    border-top: 1px solid var(--text-light);
}

.purple-mode .styled-divider-right {
    border-top: 1px solid var(--purple-dark);
}

.red-mode .styled-divider-right {
    border-top: 1px solid var(--text-light);
}

.blue-mode .styled-divider-right {
    border-top: 1px solid var(--blue-dark);
}

.yellow-mode .styled-divider-right {
    border-top: 1px solid var(--yellow-dark);
}



.scheduler-button-container {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    position: absolute;
    bottom: 0;
    right: 0;
    margin: 1rem;
}

.scheduler-button{
    padding: 0.5rem;
    border-radius: 8px;
    color: #FFFFFF;
}

.cancel {
    background-color: #B63933;
    border-color: #B63933;
}

.addJob{
    background-color: #29801B;
    border-color: #29801B;
}

.textarea-group {
    display: flex;
    margin-bottom: 1rem;
    width: 90%;
  }

.textarea {
  width: 100%;
  border: 1px solid #CBD5E0; 
  padding: 0.5rem;
}

.time-input-group {
    display: flex;
    flex-direction: column;
    margin-left: 1rem; 
  }
  
.time-input-row {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
}

.time-input-container {
    max-width: 15rem;
}

/* Styles for load type: incremental */

.incremental-container{
    display: flex;
    width: 79.5vw;
    height: 100vh;
}

.first-section {
    flex: 1;
    width: 33.33%; /* 1/3 of the total width */
    background-color: #ccc; /* Set your desired background color */
}

.second-section {
    flex: 2;
    width: 66.66%; /* 2/3 of the total width */
    background-color: #ddd; /* Set your desired background color */
}


/* one-time-occurence Styles */

.one-time-occurence-section,
.duration-section,
.occurs-once-at,
.occurs-every {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.occurs-once-at {
    justify-content: start;
    margin-bottom: 5%;
}

.occurs-once-at-2, .occurs-every-2{
    display: flex;
}

.occurs-every-2{
    margin-top: 1.5rem;
}

.daily-frequency-section-1, .daily-frequency-section-4{
    flex: 1;
}

.daily-frequency-section-3, .daily-frequency-section-2, .daily-frequency-section-5,
.daily-frequency-section-6 {
    flex: 2;
}

.daily-frequency-section-4{
    display: flex;
    flex-direction: column;
}

.daily-frequency-digital-clock-container-occurs-once-at{
    flex: 1;
    /* width: 75%; */
}

.daily-frequency-sub-section-1,
.daily-frequency-sub-section-2,
.daily-frequency-sub-section-3 {
    flex: 1;
}

.occurs-every,
.duration-section {
    justify-content: start;
}

/* .recurs-every-section {
    display: flex;
    justify-content: space-evenly;
    margin-left: 5%;
}

.recurs-every-section-1,
.recurs-every-section-2,
.recurs-every-section-3 {
    justify-content: start;
} */

.time-input-group{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin-top: 2%;
}

.daily-frequency-digital-clock-container,
.daily-frequency-digital-clock-container-occurs-once-at{
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    border: 5px solid var(--green-dark);
    background-color: var(--green-dark);
    border-radius: 10px;
    /* width: 75%; */
    margin-left: 1%;
    margin-right: 1%;
}

.daily-frequency-digital-clock-container.dark-mode{
    border: 5px solid var(--dark-medium);
    background-color: var(--dark-medium);
}

.daily-frequency-digital-clock-container.purple-mode{
    border: 5px solid var(--purple-dark);
    background-color: var(--purple-dark);
}

.red-mode .daily-frequency-digital-clock-container{
    border: 5px solid var(--red-dark);
    background-color: var(--red-dark);
}

.blue-mode .daily-frequency-digital-clock-container{
    border: 5px solid var(--blue-dark);
    background-color: var(--blue-dark);
}

.yellow-mode .daily-frequency-digital-clock-container{
    border: 5px solid var(--yellow-dark);
    background-color: var(--yellow-dark);
}

.daily-frequency-digital-1{
    padding-bottom: 1rem;
}

/* Daily Frequency - Occurs Once */

.daily-frequency-digital-clock-container-occurs-once-at.dark-mode{
    border: 5px solid var(--dark-medium);
    background-color: var(--dark-medium);
}

.daily-frequency-digital-clock-container-occurs-once-at.purple-mode{
    border: 5px solid var(--purple-dark);
    background-color: var(--purple-dark);
}

.red-mode .daily-frequency-digital-clock-container-occurs-once-at{
    border: 5px solid var(--red-dark);
    background-color: var(--red-dark);
}

.blue-mode .daily-frequency-digital-clock-container-occurs-once-at{
    border: 5px solid var(--blue-dark);
    background-color: var(--blue-dark);
}

.yellow-mode .daily-frequency-digital-clock-container-occurs-once-at{
    border: 5px solid var(--yellow-dark);
    background-color: var(--yellow-dark);
}


/* DM Proejects */

.add-more-button{
    padding: 10px;
    margin: 5px;
    width: 15%;
    cursor: pointer;
    color: black;
    border: none;
    border-radius: 10px;
    background-color: rgb(176, 236, 188);
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.add-more-button:hover{
    transform: scale(1.15); 
    color: rgb(56, 138, 72);
    opacity: 0.7;

}

.delete-button {
    padding: 10px;
    margin: 5px;
    width: 15%;
    cursor: pointer;
    color: black;
    border: none;
    border-radius: 10px;
    background-color: rgb(225, 136, 136);
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  }

  .delete-button:hover {
    transform: scale(1.2); 
    color: red;
    opacity: 0.7;
  }