.chat-container {
    display: flex;
    height: 100vh;
    background-color: #f3f4f6;
  }
  
  .chat-main {
    /* display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%; */

    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 16px;
    margin: 1rem 1rem 0 0;
    background-color: #D0C6B6;
    height: 80vh;
    /* width: 100%; */
    border-radius: 25px;
  }
  
  .chat-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    background-color: #F4F1EC;
    border-radius: 20px;
    /* border-bottom: 1px solid #374151; */
  }
  
  .header-left {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .header-right {
    display: flex;
  }
  
  .database-icon {
    color: #6b7280;
    transition: color 0.3s;
  }
  
  .database-icon.connected {
    color: #10b981;
  }
  
  .chat-content {
    flex: 1;
    overflow-y: auto;
    padding: 16px;
  }
  
  .chat-input-form {
    display: flex;
    padding: 16px;
    background-color: #ffffff;
    border-top: 1px solid #e5e7eb;
    border-radius: 20px;
    gap: 10px;
  }
  
  .chat-input-form input {
    flex: 1;
    padding: 12px;
    border: 1px solid #d1d5db;
    border-radius: 10px;
  }
  
  .chat-input-form button {
    /* padding: 12px;
    margin-left: 8px;
    background-color: #1f2937;
    color: #ffffff;
    border: none;
    border-radius: 8px;
    cursor: pointer; */

    background-color: #000000;
    color: #FFCB22;
    border: 1px solid white;
    padding: 1rem;
    display: flex;
    align-items: center;
    gap: 5px;
    border-radius: 10px;
    height: 100%;
    /* width: 5%; */
  }

  .plug-unplug-button{
    background-color: #000000;
    color: #FFCB22;
    border: 1px solid white;
    padding: 1rem;
    display: flex;
    align-items: center;
    gap: 5px;
    border-radius: 10px;
    height: 100%;
  }
  