/* Define a vibrant color palette */
:root {
  --primary-color: #a08787; /* Vivid Red */
  --secondary-color: #48744d; /* Bright Green */
  --accent-color: #e2bf86; /* Sunny Yellow */
  --text-color: #333; /* Dark Gray Text */
}

/* Apply the color palette to your styles */
.performance-project-container {
  max-width: 800px;
  margin: 0 auto;
  background-color: white;
  padding: 25px;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  position: absolute;
  bottom: 50%; /* Adjust the value based on your needs */
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
  /* Your existing styles go here... */
}

.performance-header {
  font-weight: bold;
  text-transform: uppercase;
  font-size: 28px; /* Increase the font size */
  margin-bottom: 10px; /* Add some spacing */
  font-family: 'Helvetica Neue', sans-serif;
  color: var(--accent-color); /* Use accent color for headers */
  margin-right: 30px;
}
.fw-bold {
  margin-left: -110px;
}

.custom-table-container-row {

  margin: 10px 0;
  padding: 15px;
  position: relative;
  background-color: #FFF; /* White background */
  border-radius: 5px;
}

.table-head {
  background-color: var(--accent-color); /* Use accent color for table headers */
  color: #FFF; /* White text color */
  border: none;
}

.table-outer-container {
  border: 5px solid black; /* Use secondary color for border */
  border-radius: 10px;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.9); /* Slightly transparent white background */
}

.progress-bar-container {
  width: 95%;
  height: 8px;
  background-color: #F0F0F0; /* Light gray background */
  border-radius: 5px;
  margin: 10px 0;
}

.progress-bar {
  height: 100%;
  border-radius: 5px;
  transition: width 0.3s;
  background-color: var(--accent-color); /* Use accent color for progress bar */
}

.btn-danger {
  background-color: black; /* Red background color */
  border-color: black; /* Red border color */
  color: #fff; /* White text color */
  font-weight: bold;
}

.btn-dangerclose{
  background-color: black; /* Red background color */
  border-color: black; /* Red border color */
  color: #fff; /* White text color */
  font-weight: bold;
  margin-left:360px;
  margin-top: 50px;
}

.btn-danger:hover {
  background-color: grey; /* Darker red on hover */
  border-color: grey;
}
