.signup-main-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: linear-gradient(135deg, #ece9e6, #ffffff);
}

.signup-card {
    display: flex;
    width: 80%;
    height: 80%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    overflow: hidden;
}

/* .signup-card h2 {
    margin-bottom: 20px;
    color: #222;
    text-align: center;
    font-size: 24px;
    font-weight: bold;
} */

.signup-card-left {
    width: 50%;
    background: url('https://picsum.photos/200/300') no-repeat center center;
    background-size: cover;
    position: relative;
}

.signup-image-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.6);
    color: white;
    padding: 20px;
    text-align: center;
}

.signup-card-right {
    flex: 1;
    padding: 40px;
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.signup-card-right h2 {
    margin-bottom: 20px;
    color: #333;
    text-align: center;
}

.login-form{
    /* display: grid; */
    grid-template-columns: 1fr 1fr;
    gap: 5px;
    margin: 0.5rem;
    margin: auto;
}

.signup-form {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 5px;
    margin: 0.5rem;
    margin: auto;
}

.form-group-signup {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}

.form-group-full {
    grid-column: span 2;
}

.signup-card-right label {
    margin-bottom: 5px;
    color: #666;
    font-size: 0.9rem;
}

.signup-card-right input,
.signup-card select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 10px;
    transition: border-color 0.3s, box-shadow 0.3s;
}

.signup-card-right input:focus,
.signup-card select:focus {
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.signup-card-right button {
    width: 100%;
    padding: 10px;
    background: #007bff;
    color: #fff;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    transition: background 0.3s;
}

.signup-card-right button:hover {
    background: #0056b3;
}

.signup-footer {
    margin-top: 20px;
    text-align: center;
}

.signup-footer p {
    color: #666;
}

.signup-footer a {
    color: #007bff;
    text-decoration: none;
    transition: color 0.3s;
}

.signup-footer a:hover {
    color: #0056b3;
}


/* Password strength message styles */

.password-strength,
.error,
.error-message {
    margin-top: 5px;
    font-size: 14px;
    font-weight: bold;
}

.error-message {
    text-align: center;
    display: block;
    width: 100%;
    text-align: center;

}


.password-strength.Weak,    
.error,
.error-message {
    color: red;
}

.password-strength.Medium {
    color: orange;
}

.password-strength.Strong {
    color: lightgreen;
}


.input-wrapper-password {
    position: relative;
    width: 100%;
    max-width: 400px; /* Adjust as needed */
}

.input-wrapper-password input {
    width: 100%;
    padding-right: 40px; /* Space for the eye icon */
    padding-left: 10px;
    height: 40px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    outline: none;
    transition: border-color 0.3s;
}

.input-wrapper-password input:focus {
    border-color: #007bff; /* Highlight color on focus */
}

.password-toggle-icon-login-signup {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    color: #555;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.password-toggle-icon-login-signup:hover {
    color: #007bff;
}


.forgot-password-link {
    font-size: 0.9rem;
    color: #007bff;
    text-decoration: none;
    margin-top: 5px;
    display: inline-block;
}

.forgot-password-link:hover {
    text-decoration: underline;
}
