/* Help Dialog Styles */

.help-container {
    background-color: #b4d99d;
    padding: 6px;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.dark-mode .help-container {
  background-color: var(--dark-light);
}

.purple-mode .help-container{
  background-color: var(--purple-dark);
}

.red-mode .help-container{
  background-color: var(--red-dark);
}

.blue-mode .help-container{
  background-color: var(--blue-dark);
}

.yellow-mode .help-container{
  background-color: var(--yellow-dark);
}


.help-container-closed{
    background-color: #b4d99d;
    padding: 3px;
    border-radius: 50%;    
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.dark-mode .help-container-closed {
  background-color: var(--dark-light);
}

.purple-mode .help-container-closed {
  background-color: var(--purple-light);
}

.red-mode .help-container-closed{
  background-color: var(--red-light);
}

.blue-mode .help-container-closed{
  background-color: var(--blue-light);
}

.yellow-mode .help-container-closed{
  background-color: var(--yellow-light);
}

.help-bot-dialog {
    position: fixed;
    bottom: 2.8rem;
    right: 0.8rem;
    padding: 10px;
    background-color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    z-index: 1000;
    max-width: 300px;
    border: 3px solid #b4d99d;
  }
  
  .help-bot-dialog img {
    width: 60px; 
    height: auto,
  }

  .menu-text-help-bot {
    margin: 0;
    padding: 0;
    font-size: 11px;
    color: #333; 
    font-weight: bold;
    /* margin-left: 11px; */
    margin-bottom: 0;
  }

  .dark-mode .menu-text-help-bot{
    color: var(--text-light);
  }

  .purple-mode .menu-text-help-bot{
    color: var(--purple-light);
  }

  .red-mode .menu-text-help-bot{
    color: var(--text-light);
  }

  .blue-mode .menu-text-help-bot{
    color: var(--text-light);
  }

  .yellow-mode .menu-text-help-bot{
    color: var(--text-light);
  }

  .dark-mode .help-bot-dialog{
    background-color: var(--dark-dark);
    border: 3px solid var(--dark-light);
  }

  .purple-mode .help-bot-dialog{
    background-color: var(--purple-dark);
    border: 3px solid var(--purple-light);
  }

  .red-mode .help-bot-dialog{
    background-color: var(--red-dark);
    border: 3px solid var(--red-light);
  }

  .blue-mode .help-bot-dialog{
    background-color: var(--blue-dark);
    border: 3px solid var(--blue-light);
  }

  .yellow-mode .help-bot-dialog{
    background-color: var(--yellow-dark);
    border: 3px solid var(--yellow-light);
  }

  .question-mark-style {
    cursor: 'pointer';
    border-radius: 50%;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    background: #3F787E;
    color: white;
    font-size: 20px; 
    margin-bottom: 0;
  }

  .dark-mode .question-mark-style{
    background-color: var(--dark-dark);
    color: var(--text-light);
  }

  .purple-mode .question-mark-style{
    background-color: var(--purple-dark);
    color: var(--text-light);
  }

  .red-mode .question-mark-style{
    background-color: var(--red-dark);
    color: var(--text-light);
  }

  .blue-mode .question-mark-style{
    background-color: var(--blue-dark);
    color: var(--text-light);
  }

  .yellow-mode .question-mark-style{
    background-color: var(--yellow-dark);
    color: var(--text-light);
  }