/* Container Styles */
.database-panel {
    width: 25rem; 
    background-color: #ffffff; 
    border-left: 1px solid #e5e7eb; 
    /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);  */
    display: flex;
    flex-direction: column;
    margin: 1rem 1rem 0 0;
    height: 100%;
    border-radius: 25px;
  }
  
  /* Header Styles */
  .panel-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    border-bottom: 1px solid #e5e7eb; /* Light gray border */
  }
  
  .panel-header h2 {
    font-size: 1.25rem; /* Larger font size */
    font-weight: 600; /* Semi-bold */
    color: #1f2937; /* Dark gray text */
    margin: 0;
  }
  
  .close-button {
    background: none;
    border: none;
    padding: 0.5rem;
    border-radius: 50%;
    transition: background-color 0.2s ease-in-out;
    cursor: pointer;
  }
  
  .close-button:hover {
    background-color: #f3f4f6; /* Light gray on hover */
  }
  
  /* Form Styles */
  .panel-form {
    padding: 1rem;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .form-fields {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .form-field label {
    font-size: 0.875rem; /* Small text */
    font-weight: 500; /* Medium weight */
    color: #374151; /* Gray text */
    margin-bottom: 0.25rem;
    display: block;
  }
  
  .input-field {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #d1d5db; /* Light gray border */
    border-radius: 0.375rem; /* Rounded corners */
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05); /* Subtle shadow */
    font-size: 1rem; /* Base font size */
    transition: border-color 0.2s, box-shadow 0.2s;
  }
  
  .input-field:focus {
    outline: none;
    border-color: #3b82f6; /* Blue border on focus */
    box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.25); /* Blue ring on focus */
  }
  
  /* Submit Button Styles */
  .submit-button {
    width: 100%;
    padding: 0.75rem;
    background-color: #000000; /* Dark gray */
    color: #FFCB22; /* White text */
    font-size: 1rem; /* Base font size */
    font-weight: 600; /* Semi-bold */
    border: none;
    border-radius: 0.375rem; /* Rounded corners */
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    cursor: pointer;
    transition: background-color 0.2s, transform 0.2s;
  }
  
  .submit-button:hover {
    background-color: #111827; /* Darker gray on hover */
  }
  
  .submit-button:disabled {
    background-color: #6b7280; /* Gray when disabled */
    cursor: not-allowed;
  }
  
  .submit-button .animate-spin {
    animation: spin 1s linear infinite;
  }
  
  /* Keyframes for spin animation */
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  