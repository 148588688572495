/* Dialog Container */
.p3 {
  font-family: Roboto (+ Condensed and Slab);
  font-size: 30px;
}

.dialog-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  transition: opacity 0.3s;
  opacity: 0;
  pointer-events: none;
}

.dialog-container.open {
  opacity: 1;
  pointer-events: auto;
}

.dialog-content {
  background: #617552;
  padding: 20px;
  height: 90vh;
  width: 60vw; 
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  position: relative;
}

.dark-mode .dialog-content  {
  background-color: var(--dark-dark);
  /* color: var(--text-light); */
}

.purple-mode .dialog-content  {
  background-color: var(--purple-dark);
}

.red-mode .dialog-content{
  background-color: var(--red-dark);
}

.blue-mode .dialog-content{
  background-color: var(--blue-dark);
}

.yellow-mode .dialog-content{
  background-color: var(--yellow-dark);
}


.dialog-content-inner-border{
  border: 7px solid var(--2-wcagaa-diesel-text-color-sprout, #B5D99B);
  border-radius: 10px;
  /* height: 75vh;
  width: 65vw;  */
  width: 100%; 
  height: 100%; 
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
}

.dark-mode .dialog-content-inner-border {
  background-color: var(--dark-dark);
  border: 7px solid var(--2-wcagaa-diesel-text-color-sprout, #57575f);
  /* color: var(--text-light); */
}

.purple-mode .dialog-content-inner-border{
  background-color: var(--purple-dark);
  border: 7px solid var(--2-wcagaa-diesel-text-color-sprout, #B194F1);
}

.red-mode .dialog-content-inner-border{
  background-color: var(--red-dark);
  border: 7px solid var(--2-wcagaa-diesel-text-color-sprout, #c24d61);
}

.blue-mode .dialog-content-inner-border{
  background-color: var(--blue-dark);
  border: 7px solid var(--2-wcagaa-diesel-text-color-sprout, #03b6da);
}

.yellow-mode .dialog-content-inner-border{
  background-color: var(--yellow-dark);
  border: 7px solid var(--2-wcagaa-diesel-text-color-sprout, #d4b412);
}

/* .progress-section{
  padding-left: 10%;
  padding-right: 10%; */
  /* padding-bottom: 5%; */
  /* padding-top: 5%;
} */

.buttons-container {
  position: absolute;
  bottom: 3.5%;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 10px; 
}

oo
.label-containerport{
  font-weight: bold;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  color: #FFFFFF;
  font-size: 22px;
  letter-spacing: 0em;
  margin-right: 410px;
  margin-bottom: 10px; /* Add margin-bottom to create a gap between label and input */
}

/* .dialog-content h1 {
  font-size: 28px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  color: black; 
  margin-top: 30px;
} */

.inner-border {
  border: 4px solid black; /* Add an inner border with the desired color */
  position: absolute; /* Position the inner border absolutely */
  top: 12px; /* Adjust the top position to match your padding */
  left: 12px; /* Adjust the left position to match your padding */
  right: 12px; /* Adjust the right position to match your padding */
  bottom: 12px; /* Adjust the bottom position to match your padding */
  border-radius: 8px; /* Adjust border radius as needed */
}



/* Dialog Heading */
.dialog-heading {
  font-size: 24px;
  margin: 0;
  color: #FFFFFF;
  text-align: center; 
  flex-direction: column;
  /* align-items: center; 
  justify-content: center; */
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.close-button {
  position: absolute;
  top: 3.5%;
  right: 0.5%;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 24px;
  color: black; 
  transition: color 0.3s;
}

.close-button:hover {
  color: black;
}

.button-container12 {
  display: flex;
  justify-content: space-between;
  /* align-items: center;  */
  position: relative; 
  z-index: 1; 
}

.button-container12::before {
  content: '';
  position: absolute;
  transform: translateY(-50%);
  top: 25%; 
  left: 1%; 
  right: 1%;
  height: 4px;
  background-color: #B5D99B;
  z-index: -1;
}

.dark-mode .button-container12::before{
  background-color: var(--dark-light);
  /* color: var(--text-light); */
}

.purple-mode .button-container12::before{
  background-color: var(--purple-default);
}

.red-mode .button-container12::before{
  background-color: var(--red-default);
}

.blue-mode .button-container12::before{
  background-color: var(--blue-default);
}

.yellow-mode .button-container12::before{
  background-color: var(--yellow-default);
}

.step-label{
  color: white;
}

.step {
  text-align: center;
  margin: 0 12px; 
}

.step p {
  margin-top: 0px;
  font-size: 12px;
  /* color: black; */
}

.step-content-section{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  /* margin-top: 1.5rem; */
}

.button3 {
  background-color: black;
  color: black;
  padding: 0;
  border: none;
  border-radius: 50%;
  width: 50px; 
  height: 50px; 
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  margin: 0;
}

.button-active {
  background-color: #FFFFFF;
}

.button-inactive {
  background-color: #ABB5A3;
}

.button7 {
  border: 5px solid #B5D99B; /* Add a border to the button */
  border-radius: 50%; /* Make the button circular by using border-radius 50% */
}

.dark-mode .button7 {
  border: 5px solid #57575f; /* Add a border to the button */
  border-radius: 50%; /* Make the button circular by using border-radius 50% */
}

.purple-mode .button7{
  border: 5px solid #B194F1; /* Add a border to the button */
  border-radius: 50%; /* Make the button circular by using border-radius 50% */
}

.red-mode .button7{
  border: 5px solid #c24d61; /* Add a border to the button */
  border-radius: 50%; /* Make the button circular by using border-radius 50% */
}

.blue-mode .button7{
  border: 5px solid #03b6da; /* Add a border to the button */
  border-radius: 50%; /* Make the button circular by using border-radius 50% */
}

.yellow-mode .button7{
  border: 5px solid #d4b412; /* Add a border to the button */
  border-radius: 50%; /* Make the button circular by using border-radius 50% */
}



.label-container1 {
  font-weight: bold;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  color: black;
  font-size: 22px;
  letter-spacing: 0em;
  margin-right: 360px;
  margin-bottom: 10px; /* Add margin-bottom to create a gap between label and input */
}

.label-container4{
  font-weight: bold;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  color: black;
  font-size: 22px;
  letter-spacing: 0em;
  margin-right: 430px;
  margin-bottom: 10px; /* Add margin-bottom to create a gap between label and input */
}

.addnewname-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  margin-left: 20%;
}

/* .center-content1{
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  height: 40%;
} */

/* Add New Name Container */
.addnewname {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  margin: auto;
  width: 100%; 
  border-radius: 2px black;
  margin-top:2rem;
}

/* .addnewname label {
  font-family: Poppins;
  font-size: 25px;
  font-weight: 400;
  line-height: 38px;
  letter-spacing: 0em;
  text-align: left;  
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  margin-left: 0px;
  margin-right: auto;
  color: black;
  margin-bottom: 20px;
}

.addnewname input[type="text"] {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  margin-bottom: 30px;
  width: 100%;
} */



.header-title-addProjects{
  color: var(--2-wcagaa-diesel-text-color-white, #FFF);
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: center;
}

.buttonMain {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 1rem;
  font-weight: 500;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  width: auto;
  margin: 4px 8px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.2s;
  white-space: nowrap;
  position: absolute;
  bottom: 65%;
  transition: background-color 0.3s ease-in-out;
}

.buttonMain:disabled {
  opacity: 0.5; 
  cursor: not-allowed; 
}

.dark-mode .buttonMain {
  background-color: var(--dark-light);
  color: white;
  border: 1px solid var(--2-wcagaa-diesel-text-color-sprout, --dark-dark);

  /* color: var(--text-light); */
}

.purple-mode .buttonMain{
  background-color: var(--purple-default);
  color: white;
  border: 1px solid var(--2-wcagaa-diesel-text-color-sprout, --purple-dark);
}

.red-mode .buttonMain{
  background-color: var(--red-default);
  color:white;
  border: 1px solid var(--2-wcagaa-diesel-text-color-sprout, --red-dark);
}

.blue-mode .buttonMain{
  background-color: var(--blue-default);
  color:white;
  border: 1px solid var(--2-wcagaa-diesel-text-color-sprout, --blue-dark);
}

.yellow-mode .buttonMain{
  background-color: var(--yellow-default);
  color:white;
  border: 1px solid var(--2-wcagaa-diesel-text-color-sprout, --yellow-dark);
}



.buttonColorSaveAsDraft{
  background-color: #FFFFFF;
  color: #3F787E;
  border: 1px solid var(--2-wcagaa-diesel-text-color-sprout, #B5D99B);
  left: 3.5%;
}

.dark-mode .buttonColorSaveAsDraft {
  border: 1px solid var(--2-wcagaa-diesel-text-color-sprout, --dark-dark);
  /* color: var(--text-light); */
}

.purple-mode .buttonColorSaveAsDraft {
  border: 1px solid var(--2-wcagaa-diesel-text-color-sprout, --purple-dark);
  /* color: var(--text-light); */
}

.red-mode .buttonColorSaveAsDraft {
  border: 1px solid var(--2-wcagaa-diesel-text-color-sprout, --red-dark);
  /* color: var(--text-light); */
}

.blue-mode .buttonColorSaveAsDraft {
  border: 1px solid var(--2-wcagaa-diesel-text-color-sprout, --blue-dark);
  /* color: var(--text-light); */
}

.yellow-mode .buttonColorSaveAsDraft {
  border: 1px solid var(--2-wcagaa-diesel-text-color-sprout, --yellow-dark);
  /* color: var(--text-light); */
}


.buttonValidate{
  background-color: #B5D99B;
  color:white;
  border: 1px solid var(--2-wcagaa-diesel-text-color-sprout, #3F787E);
  right: 18%;
  /* bottom: 65%; */
}

.dark-mode .buttonValidate {
  background-color: var(--dark-light);
  color:white;
  /* color: var(--text-light); */
}

.purple-mode .buttonValidate{
  background-color: var(--purple-default);
  color:white;
}

.red-mode .buttonValidate{
  background-color: var(--red-default);
  color:white;
}

.blue-mode .buttonValidate{
  background-color: var(--blue-default);
  color:white;
}

.yellow-mode .buttonValidate{
  background-color: var(--yellow-default);
  color:white;
}

.buttonSaveAndRun{
  background-color: #B5D99B;
  color:white;
  border: 1px solid var(--2-wcagaa-diesel-text-color-sprout, #3F787E);
  right: 18%;
}

.dark-mode .buttonSaveAndRun{
  background-color: var(--dark-light);
  color: white;
}

.purple-mode .buttonSaveAndRun {
  background-color: var(--purple-default);
  color: var(--purple-light);
}

.red-mode .buttonSaveAndRun{
  background-color: var(--red-default);
  color: var(--red-light);
}

.blue-mode .buttonSaveAndRun{
  background-color: var(--blue-default);
  color: var(--blue-light);
}

.yellow-mode .buttonSaveAndRun{
  background-color: var(--yellow-default);
  color:white;
}

.checkmark {
  margin-left: 5px; 
  color: #1df557; 
}

.buttonColorSave{
  background-color: #B5D99B;
  color:white;
  border: 1px solid var(--2-wcagaa-diesel-text-color-sprout, #3F787E);
  right: 3.5%;
}

.button1 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 18px;
  font-weight: 500;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  width: 130px;
  margin: 4px 8px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.2s;
}
.button5 {
  background-color: black; /* Change to a shade of black */
}

.button5:hover {
  background-color: black; /* Darker shade of black on hover */
}


.button2 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 18px;
  font-weight: 500;
  color: black;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 4px 8px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.2s;
}
.button6 {
  background-color: white; /* Change to a shade of black */
}

.button6:hover {
  background-color: whitesmoke; /* Darker shade of black on hover */
}


.next-button {
  position: absolute;
  bottom: 15px;
  left: 80%;
}


.next-button1 {
  position: absolute;
  bottom: 15px;
  right: 80%;
}

.next-button2 {
  position: absolute;
  bottom: 15px;
  left: 400px;
}

.next-button4 {
  position: absolute;
  bottom: 15px;
  left: 400px;
  width:140px;
}

.next-button3 {
  position: absolute;
  bottom: 15px;
  left: 300px;
}

/* Style for the source system configuration inputs and labels */
.sourcesystem-config {
  display: flex;
  flex-direction: column;
  gap: 20px; /* Adjust the gap between elements as needed */
}

 /* Input Fields and Labels */
 .label-input-container {
  margin: 10px 0;
}

.progress-labels {
  color: var(--2-wcagaa-diesel-text-color-white, #FFF);
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 200;
  line-height: normal;
}

.label-container {
  font-weight: bold;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  color: black;
  font-size: 22px;
  letter-spacing: 0em;
  margin-right: 490px;
  margin-bottom: 10px; /* Add margin-bottom to create a gap between label and input */
}
.label-container2 {
  font-weight: bold;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  color: black;
  font-size: 22px;
  letter-spacing: 0em;
  margin-right: 399px;
  margin-bottom: 10px; /* Add margin-bottom to create a gap between label and input */
}
.input-container input {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  margin-bottom: 10px;
  width: 80%;
}

.input-container-source-target {
  position: relative;
}

/* .input-wrapper {
  position: relative;
  width: 100%; 
} */

.input-container-source-target input {
  width: 100%; 
  border: none;
  border-radius: 10px;
  height: 37px;
  padding-left: 30px;
  margin-top: 8px;
}

.password-toggle-icon {
  position: absolute;
  right: 10px; 
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  color: #617552
}


.password-toggle-icon.visible {
  color: #617552; 
  z-index: 1; 
}

/* Style for the error text (if applicable) */
.error-text {
  color: black; /* Red color for error messages */
}

/* Style for the success text (if applicable) */
.success-text {
  color: black; /* Green color for success messages */
}

 /* Input Fields and Labels */
 .label-input-container {
  margin: 10px 10px;;
}

.label-container {
  font-weight: bold;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  color: black;
  font-size: 22px;
  letter-spacing: 0em;
  margin-right: 400px;
  margin-bottom: 10px; /* Add margin-bottom to create a gap between label and input */
}

.input-container input {
  padding: 5px;
  border: 1px solid black;
  border-radius: 5px;
  font-size: 16px;
  margin-bottom: 10px;
  width: 500px;
}

.mapping-container{
  /* overflow: auto; */
  max-height: 40vh;
  max-width: 50vw;
}

.mapping{
  /* background-color:#617552 ; */
  /* margin-bottom:30% ;
  padding-bottom: 20%; */
  width: 90%;
  
  /* height: 50vh; */
  /* margin-left: 20px; */
}

.sourcesystem-config {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.input-row {
  display: flex;
  margin-left: 70px;
}

.table-header{
  color:black;
  background-color: #ccc;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.input-label {
  width: 160px; /* Adjust this width as needed */
}

.checkbox-label {
  display: flex;
  align-items: center;
  margin-left: 18px;
}

.checkbox-input {
  margin-right: 10px;
}

.checkbox-label-text {
  font-size: 16px;
}

/* Style the container */
.table-container {
  display: flex;
  justify-content: center;
  /* margin-top: 20px; */
  overflow: auto;
  max-height: 17rem;
}

.dual-table {
  width: 80%;
  border-collapse: collapse;
  table-layout: fixed;
  background-color: white;
}

.dual-table th{
  padding: 10px;
  text-align: center;
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis; 
  vertical-align: middle;
  background-color: #ccc;
  width: 250px;
  font-weight: bold;
}
.dual-table td {
  padding: 10px;
  text-align: right;
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis; 
  vertical-align: middle;
  background-color: white;
  width: 250px;
  font-weight: bold;
}

.dual-table td div {
  max-height: 50px; /* Adjust the maximum height as needed */
  overflow: auto;
  background-color: white;


  /* Scrollbar Styles */
  scrollbar-width: thin; /* for Firefox */
  scrollbar-color: #ccc #f5f5f5; /* color for the track and thumb */
}

/* Style for webkit-based browsers (Chrome and Safari) */
.checkbox-label {
  display: flex;
  align-items: center;
  gap: 10px;
}

.checkbox-input {
  margin: 0;
}

.checkbox-label-text {
  font-size: 14px;
}


/* Style for the file upload button */
.file-upload-button {
  background-color: black;
  color: #fff;
  padding: 0px 20px;
  border-radius: 5px;
  margin-left: 605px;
  cursor: pointer;
}


/* Style for the select element */
.selectvalue {
  width: 35vw;
  /* padding: 10px; */
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  color: #333;
  cursor: pointer;
}

/* Style for thse select container */
.option-container {
  padding: 10px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 16px;
  background-color: #fff;
  color: #333;
  overflow-y: auto;
  max-height: 200px; /* Set a max height to enable scrolling */
  transition: background-color 0.3s ease; /* Hover effect transition */
}

/* Style for the selected option */
.selectvalue option:checked {
  background-color: black;
  color: #fff;
}

/* Style for option hover effect */
.option-container:hover {
  background-color: #70C652;
  color: #fff;
  cursor: pointer;
  border: 1px solid #70C652;
}

/* Style for the scrollbar */
.option-container::-webkit-scrollbar {
  width: 12px; /* Width of the scrollbar */
}

.option-container::-webkit-scrollbar-thumb {
  background-color: white; /* Color of the scrollbar thumb */
  border-radius: 6px; /* Round the thumb */
}

.option-container::-webkit-scrollbar-thumb:hover {
  background-color: white; /* Color of the scrollbar thumb on hover */
}

/* styles.css */
#openMappingDialogButton {
  background-color: white;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  box-shadow: 2px 2px 4px #888;
}

.new-button-container {
  display: flex;
  flex-direction: column; /* Align buttons vertically */
  align-items: flex-start; /* Align buttons to the start of the container */
  justify-content: flex-end; /* Move buttons to the right */
  margin-bottom: 3rem; /* Add your desired margin */
  margin-left: 3rem; /* Move buttons 5cm towards right */
  /* background-color: #617552; */
}

.new-button-sel,
.new-button-uns-forward,
.new-button-uns,
.new-button-uns-forward {
  background-color: black; /* Add your desired background color */
  color: #fff; /* Add your desired text color */
  border: 1px solid black; /* Add your desired border color */
  padding: 5px 10px; /* Add your desired padding */
  cursor: pointer;
  margin-bottom: 5px; /* Add spacing between buttons */
}

.new-button-sel:hover,
.new-button-uns-forward:hover,
.new-button-uns:hover,
.new-button-uns-forward:hover {
  background-color: black; /* Add your desired background color on hover */
}

.table-box-container {
  display: flex;
  justify-content: space-between;
  /* margin-top: 1cm;  */
  /* margin-right: 5%;
  margin-left: 5%; */
  /* width:100%; */
}

.table-container {
  width: 78%; /* Adjust the width as needed */
  margin-left: 50px;
}

.table-container + .table-container {
  margin-left: 1cm; /* Adjust the margin as needed */
}

.fixed-header {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;
}

.button-space {
  width: 0cm;
  background-color: lightblue;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.validation-result{
  color: black;
}

/* .target-config{
  width: 70vw;
  height: 50vh;
} */