/* DirectProjectMapping.css */

.mapping-container-add-project {
    display: flex;
    flex-direction: column; /* Stack rows vertically */
    /* gap: 20px; */
    padding: 20px;
    border: 2px solid #ddd;
    border-radius: 10px;
    background-color: #f9f9f9;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    height: 100%;
    overflow: hidden;
}

.sources-and-targets {
    display: flex;
    justify-content: space-between;
    flex: 1;
    /* gap: 20px; */
}

.sources-list{
    border-top-left-radius: 10px;
}

.targets-list{
    border-top-right-radius: 10px;
}

.sources-list, .targets-list {
    flex: 1;
    border: 1px solid #ccc;
    /* flex-direction: column; */
    /* border-radius: 10px; */
    padding: 10px;
    background: #ffffff;
    display: flex;
    flex-wrap: wrap; 
    gap: 10px;
    list-style-type: none;
    overflow-y: auto;
}

.sources-list::before, .targets-list::before {
    content: attr(data-label);
    display: block;
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 10px;
}

.sources-list .source-item, .targets-list .target-item {
    flex: 1 1 calc(25% - 10px); 
    min-width: 120px;
    padding: 8px;
    text-align: center;
    border: 1px solid #ddd;
    border-radius: 6px;
    background-color: #e9f7fe;
    color: #333;
    font-size: 0.9rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    list-style-type: none;
}

.sources-list ul,
.targets-list ul {
    list-style-type: none; 
    padding: 0;
    margin: 0; 
}

/* DirectProjectMapping.css */

.mapping-actions {
    display: flex;
    justify-content: space-around; /* Even spacing between buttons */
    align-items: center;
    gap: 10px; /* Add space between buttons */
    padding: 10px 0;
    width: 100%; /* Ensure full width */
    background-color: #f5f5f5; /* Optional: Background for distinction */
    border-top: 1px solid #ccc; /* Optional: Separate from above sections */
}

.mapping-actions button {
    flex: 1; /* Make all buttons take equal space */
    /* max-width: 150px;  */
    padding: 10px;
    font-size: 0.9rem;
    font-weight: 600;
    color: #fff;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    text-align: center;
    background-color: #3F787E;
}

.mapping-actions button:hover {
    opacity: 0.9;
}
/* 
.mapping-actions .btn-uncheck {
    background-color: #e74c3c;
}

.mapping-actions .btn-select-all {
    background-color: #3498db;
}

.mapping-actions .btn-move-right {
    background-color: #2ecc71;
}

.mapping-actions .btn-deselect {
    background-color: #f39c12;
} */


/* Responsive Design */
@media (max-width: 768px) {
    .sources-and-targets {
        flex-direction: column; /* Stack sources and targets vertically */
    }

    .sources-list .source-item, .targets-list .target-item {
        flex: 1 1 calc(50% - 10px); /* 2 columns on smaller screens */
    }
}

@media (max-width: 480px) {
    .sources-list .source-item, .targets-list .target-item {
        flex: 1 1 100%; /* Full-width on smallest screens */
    }

    .mapping-actions {
        flex-direction: column; /* Stack buttons vertically */
        gap: 10px;
    }
}
