.calendar-input-container {
    position: relative;
    background-color: #3F787E;
    border-radius: 10px;
    border-color: #3F787E;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    overflow: hidden;
    transition: height 0.5s ease-in-out;
    margin-right: 5rem;  
  }

  .calendar-input-container.dark-mode{
    background-color: var(--dark-dark);
  }

  .calendar-input-container.purple-mode{
    background-color: var(--purple-dark);
  }

  .red-mode .calendar-input-container{
    background-color: var(--red-dark);
  }

  .calendar-input-container.blue-mode{
    background-color: var(--blue-dark);
  }
  
  .calendar-input-container.yellow-mode{
    background-color: var(--yellow-dark);
  }
  
  
  .top-left-text {
    color: #ffffff;
    padding: 0.5rem;
    font-size: 0.8rem;
    align-self: flex-start;
    transition: opacity 0.5s ease-in-out;
  }
  
  .center-area {
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: opacity 0.1s ease-in-out, margin-top 0.5s ease-in-out;
  }
  
  .center-text {
    color: #ffffff;
    padding: 0.5rem 1rem;
    font-size: 1.5rem;
    align-self: flex-start;
    transition: opacity 0.5s ease-in-out, margin-top 0.5s ease-in-out;
  }
  
  .horizontal-line {
    width: 100%;
    height: 1px;
    background-color: #ccc;
    /* margin: 10px 0; */
    transition: opacity 0.5s ease-in-out;
  }
  
  .calendar-input {
    display: flex;
    align-items: center;
    border: 3px solid #ccc;
    border-radius: 8px;
    transition: opacity 0.5s ease-in-out;
  }
  
  .input-border {
    border-radius: 8px;
    padding: 1rem 1rem;
  }
  
  .calendar-text-input {
    width: 100%;
    padding: 8px;
    border: none;
    outline: none;
    background-color: #3F787E;
    color: white;
    margin-left: 0.5rem;
  }

  .calendar-text-input.dark-mode{
    background-color: var(--dark-dark);
  }

  .calendar-text-input.purple-mode{
    background-color: var(--purple-dark);
  }

  .red-mode .calendar-text-input{
    background-color: var(--red-dark);
  }

  .calendar-text-input.blue-mode{
    background-color: var(--blue-dark);
  }
  
  .calendar-text-input.yellow-mode{
    background-color: var(--yellow-dark);
  }
  
  
  .calendar-icon {
    cursor: pointer;
  }
  
  .bottom-right-corner {
    display: flex;
    justify-content: flex-end;
    align-self: flex-end;
    padding: 0.5rem;
    transition: opacity 0.5s ease-in-out;
  }
  
  .bottom-right-corner button {
    border: none;
    background-color: transparent;
    color: white;
    padding: 0;
    cursor: pointer;
    margin-left: 8px;
    font-size: 0.8rem;
  }
  
  .calendar-input-container.calendar-open .top-left-text,
  .calendar-input-container.calendar-open .center-text,
  .calendar-input-container.calendar-open .horizontal-line {
    opacity: 0;
  }
  
  /* Moves center-area up when calendar is open */
  .calendar-input-container.calendar-open .center-area {
    margin-top: -5rem; 
  }
  
  /* Moves calendar-container up when calendar is open */
  .calendar-container {
    /* margin-top: 2rem;  */
    visibility: hidden;
    opacity: 0;
    transition: margin-top 0.5s ease-in-out, visibility 0.5s, opacity 0.5s;
  }
  
  /* Shows calendar-container when calendar is open */
  .calendar-input-container.calendar-open .calendar-container {
    margin-top: 1rem;
    visibility: visible;
    opacity: 1;
  }
  


/* Main Calendar Container */
.react-calendar {
  width: 225px;
  max-width: 100%;
  background-color: var(--green-light); /* Default background color */
  color: #222;
  border-radius: 8px;
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
  border-color: var(--green-dark);
  margin: 1rem;
  margin-top: 0;
}

/* Theme-based styles for Main Calendar Container */
.react-calendar.dark-mode {
  background-color: var(--dark-dark); 
  border-color: var(--dark-light);
}

.react-calendar.purple-mode {
  background-color: var(--purple-light);
  border-color: var(--purple-dark);
}

.react-calendar.red-mode {
  background-color: var(--red-light);
  border-color: var(--red-dark);
}

.react-calendar.blue-mode {
  background-color: var(--blue-light);
  border-color: var(--blue-dark);
}

.react-calendar.yellow-mode {
  background-color: var(--yellow-light);
  border-color: var(--yellow-dark);
}

/* Navigation Buttons */
.react-calendar__navigation button {
  color: #49454F;
  min-width: 44px;
  background: none;
  font-size: 16px;
  margin-top: 8px;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #f8f8fa;
}

.react-calendar__navigation button[disabled] {
  background-color: #f0f0f0;
}

/* Abbreviation Titles */
abbr[title] {
  text-decoration: none;
}

/* Individual Calendar Tiles */
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background: #f8f8fa;
  color: #3F787E;
  border-radius: 6px;
}

/* Current Date Tile */
.react-calendar__tile--now {
  background: #6f48eb33; /* Default background color for "now" tile */
  border-radius: 6px;
  font-weight: bold;
  color: #3F787E; /* Default text color for "now" tile */
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #6f48eb33;
  border-radius: 6px;
  font-weight: bold;
  color: #3F787E;
}

/* Active Date Tile */
.react-calendar__tile--active {
  background: #3F787E;
  border-radius: 6px;
  font-weight: bold;
  color: white;
}

.react-calendar__tile--active.dark-mode {
  background-color: var(--dark-dark); 
  border-color: var(--dark-light);
}

.react-calendar__tile--active.purple-mode {
  background-color: var(--purple-dark);
  border-color: var(--purple-dark);
}

.react-calendar__tile--active.red-mode {
  background-color: var(--red-light);
  border-color: var(--red-dark);
}

.react-calendar__tile--active.blue-mode {
  background-color: var(--blue-light);
  border-color: var(--blue-dark);
}

.react-calendar__tile--active.yellow-mode {
  background-color: var(--yellow-light);
  border-color: var(--yellow-dark);
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #3F787E;
  color: white;
}

/* Date Tile with Active Range */
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #f8f8fa;
}

/* Date Tile Range */
.react-calendar__tile--range {
  background: #f8f8fa;
  color: #3F787E;
  border-radius: 0;
}

/* Range Start Date Tile */
.react-calendar__tile--rangeStart {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  background: #3F787E;
  color: white;
}

/* Range End Date Tile */
.react-calendar__tile--rangeEnd {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  background: #3F787E;
  color: white;
}
