.digital-clock-content{
    display: flex;
    flex-direction: column;
    /* background-color: blue; */
}

.HHMM-container,
.AMPM-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.HH-input,
.MM-input{
    height: 10vh;
    width: 10vw;
    /* background-color: blue; */
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 3.5rem;
    color: black;
    border-radius: 10px;
    border: none;
}

.HH-input{
    background-color: var(--green-dark);
    color: var(--text-light);
}

.MM-input{
    color: var(--green-dark);
}

/* Dark Mode */

.dark-mode .HH-input{
    background-color: var(--dark-default);
    color: var(--text-light);
}

.dark-mode .MM-input{
    color: var(--dark-default);
}

/* Purple Mode */

.purple-mode .HH-input{
    background-color: var(--purple-dark);
    color: var(--text-light);
}

.purple-mode .MM-input{
    color: var(--purple-dark);
}

/* Red Mode */

.red-mode .HH-input{
    background-color: var(--red-dark);
    color: var(--text-light);
}

.red-mode .MM-input{
    color: var(--red-dark);
}

/* Blue Mode */

.blue-mode .HH-input{
    background-color: var(--blue-dark);
    color: var(--text-light);
}

.blue-mode .MM-input{
    color: var(--blue-dark);
}

/* Yellow Mode */

.yellow-mode .HH-input{
    background-color: var(--yellow-dark);
    color: var(--text-light);
}

.yellow-mode .MM-input{
    color: var(--yellow-dark);
}





.AMPM-container{
    margin-top: 10%;
    border: 1px solid var(--green-default);
    border-radius: 10px;
    /* padding: 5px; */
}

.AM-input,
.PM-input{
    width: 10vw;
    height: 5vh;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background-color: #f0f0f0;
    color: var(--green-dark);
    padding: 5px 10px;
    cursor: pointer;
    /* background-color: yellow; */
}

.active-period {
    background-color: var(--green-dark);
    color: #fff;
}

.AM-input:hover,
.PM-input:hover {
    background-color: var(--green-light);
}

.AM-input{
    border-right: 1px solid var(--green-default);
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.PM-input{
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

/* Dark Mode */

.dark-mode.AMPM-container{
    border: 1px solid var(--dark-light);
}

.dark-mode.active-period{
    background-color: var(--dark-default);

}

.dark-mode.AM-input:hover,
.dark-mode.PM-input:hover {
    background-color: var(--dark-default);
}

.dark-mode.AM-input{
    border-right: 1px solid var(--dark-light);
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    color: var(--dark-default);
}

/* Purple Mode */

.purple-mode.AMPM-container{
    border: 1px solid var(--purple-default);
}

.purple-mode.active-period{
    background-color: var(--purple-dark);
}

.purple-mode.AM-input:hover,
.purple-mode.PM-input:hover {
    background-color: var(--purple-default);
}

.purple-mode.AM-input{
    border-right: 1px solid var(--purple-default);
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

/* Red Mode */

.red-mode .AMPM-container{
    border: 1px solid var(--red-default);
}

.red-mode .active-period{
    background-color: var(--red-dark);
}

.red-mode .AM-input:hover,
.red-mode .PM-input:hover {
    background-color: var(--red-default);
}

.red-mode .AM-input{
    border-right: 1px solid var(--red-default);
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

/* Blue Mode */

.blue-mode.AMPM-container{
    border: 1px solid var(--blue-default);
}

.blue-mode.active-period{
    background-color: var(--blue-dark);
}

.blue-mode.AM-input:hover,
.blue-mode.PM-input:hover {
    background-color: var(--blue-default);
}

.blue-mode.AM-input{
    border-right: 1px solid var(--blue-default);
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

/* Yellow Mode */

.yellow-mode.AMPM-container{
    border: 1px solid var(--yellow-default);
}

.yellow-mode.active-period{
    background-color: var(--yellow-dark);
}

.yellow-mode.AM-input:hover,
.yellow-mode.PM-input:hover {
    background-color: var(--yellow-default);
}

.yellow-mode.AM-input{
    border-right: 1px solid var(--yellow-default);
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

