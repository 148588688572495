/* Hide the default scrollbar for Chrome and Safari */
.scrollable-box::-webkit-scrollbar,
.scrollable-box-AddProjectsDialog::-webkit-scrollbar {
  width: 0.5em; /* Adjust the width as needed */
}

.scrollable-box::-webkit-scrollbar-track,
.scrollable-box-AddProjectsDialog::-webkit-scrollbar-track {
  background: #f1f1f1; /* Track color */
}

.scrollable-box::-webkit-scrollbar-thumb{
  background-color: #7FE7F2; /* Thumb color */
  border-radius: 10px; /* Rounded corners */
}

.yellow-mode .scrollable-box::-webkit-scrollbar-thumb{
  scrollbar-color: var(--yellow-default);
  border-radius: 10px; 
}

.dark-mode .scrollable-box::-webkit-scrollbar-thumb{
  scrollbar-color: var(--dark-light);
  border-radius: 10px; 
}

.scrollable-box-AddProjectsDialog::-webkit-scrollbar-thumb {
  background-color: #B5D99B; /* Thumb color */
  border-radius: 10px;
}

/* For Firefox */
.scrollable-box,
.scrollable-box-AddProjectsDialog,
.scrollable-box-sql-project-details,
.scrollable-box-direct-mapping,
.scrollable-box-incremental-mapping,
.scrollable-box-all-users-admin-dashboard {
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
  scrollbar-color: var(--green-default) #f1f1f1;
}
.scrollable-box {
    max-height: 280px; 
    overflow-y: scroll;
    table-layout: fixed;
    /* margin-left: 100px; */
  }

.scrollable-box-dashoard {
  width: 100%;
}

.scrollable-box-sql-project-details {
  max-height: 45vh; 
  overflow-y: scroll;
  table-layout: fixed;
  /* margin-left: 100px; */
}

.scrollable-box-all-users-admin-dashboard {
  /* max-height: 40vh;  */
  overflow-y: scroll;
  table-layout: fixed;
  /* margin-left: 100px; */
}

  .scrollable-box.dark-mode{
    scrollbar-width: thin;
    scrollbar-color: var(--dark-light) #f1f1f1;
  }

  .scrollable-box.purple-mode{
    scrollbar-width: thin;
    scrollbar-color: var(--purple-default) #f1f1f1;
  }

  .scrollable-box.red-mode{
    scrollbar-width: thin;
    scrollbar-color: var(--red-default) #f1f1f1;
  }

  .scrollable-box.blue-mode{
    scrollbar-width: thin;
    scrollbar-color: var(--blue-default) #f1f1f1;
  }

  .scrollable-box.yellow-mode{
    scrollbar-width: thin;
    scrollbar-color: var(--yellow-default) #f1f1f1;
  }

.scrollable-box-AddProjectsDialog {
    max-height: 17.5rem; 
    width: 100%;
    overflow-y: scroll;
    table-layout: fixed;
    /* overflow: hidden; */
    /* margin-left: 100px; */
  }

.scrollable-box-theme-selection {
    max-height: 100%; 
    width: 80%;
    margin-left: 10px;
    overflow-y: scroll;
    overflow-x: none;
    table-layout: fixed;
    /* overflow: hidden; */
    /* margin-left: 100px; */
}

.scrollable-box-direct-mapping {
  overflow-y: scroll;
  table-layout: fixed;
  max-height: 250px;
}

.scrollable-box-incremental-mapping {
  overflow-y: scroll;
  table-layout: fixed;
  /* max-height: 250px; */
}
  
  .mt-4 {
    margin-top: 4px;
  }
  .mt-2 {
    margin-top: 2px;
  }
  
  .custom-table {
    display: flex;
    flex-direction: column;
  }
  
  .custom-table-header,
  .custom-table-row {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #ddd; 
    padding: 8px; 
  }
  
  /* .custom-table-row:hover {
    background-color: #f2f2f2; 
  }
   */
  .custom-table-header {
    font-weight: bold;
    /* background-color: #e0e0e0; */
  }

  .custom-table-body {
    overflow-y: auto;
  }
  
  .custom-table-cell {
    display: flex;
    align-items: center;
    width: 20%;
  }
  
  .custom-table-row:not(:last-child) {
    margin-bottom: 4px; 
  }
  

  .link-styling {
    color: #007bff; 
    text-decoration: none;
  }
  