.add-user-container {
    display: flex;
    height: 100%;
    background-color: #f4f4f9;
}

/* .add-user-left {
    flex: 1;
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #f9ecec;
    text-align: center;
    border-right: 2px solid #ddd;
} */

.add-user-left{
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #f5e8e8; /* Pastel pink background */
    padding: 20px;
    text-align: center;
}

.add-user-left h2 {
    font-size: 24px;
    color: #6b6b6b;
    margin-bottom: 10px;
}

.add-user-left p {
    font-size: 16px;
    color: #4f4f4f;
    max-width: 400px;
}

.admin-message-title {
    font-size: 24px;
    color: #333;
    margin-bottom: 20px;
}

.admin-message {
    font-size: 16px;
    color: #666;
    line-height: 1.5;
}

.add-user-right {
    flex: 1;
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #fff;
}

.form-header {
    text-align: center;
    margin-bottom: 30px;
    font-size: 24px;
    color: #333;
}

.form-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.form-grid {
    display: flex;
    gap: 40px;
}

.form-column {
    flex: 1;
    display: flex;
    flex-direction: column;
    /* gap: 20px; */
}

label {
    font-size: 14px;
    font-weight: bold;
    color: #555;
}

input {
    margin-top: 5px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    width: 100%;
}

.form-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 15px;
    margin-top: 20px;
}

.submit-button {
    padding: 12px 25px;
    font-size: 14px;
    color: #fff;
    background-color: #4caf50;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.cancel-button {
    padding: 12px 25px;
    font-size: 14px;
    color: #fff;
    background-color: #f44336;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.success-message {
    color: #4caf50;
    font-weight: bold;
    text-align: center;
    margin-top: 20px;
}

.error-message {
    color: #f44336;
    font-weight: bold;
    text-align: center;
    margin-top: 20px;
}
